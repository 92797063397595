import produce from 'immer';
import types from './types';
import initialState from './initialState';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_NUMBER_OF_PLAYERS: {
      draft.numberOfPlayers = parseInt(data, 10);

      break;
    }

    case types.SET_SHOW_INCLUDED_SKILLS: {
      draft.showIncludedSkills = data;

      break;
    }

    case types.SET_SHOW_ACTIVE_SKILLS: {
      draft.showActiveSkills = data;

      break;
    }

    case types.SET_SHOW_PASSIVE_SKILLS: {
      draft.showPassiveSkills = data;

      break;
    }

    case types.SET_SHOW_PHASE_MODAL: {
      draft.showPhaseModal = data;

      break;
    }


    case types.SET_SHOW_LEVEL_PHASE_MODAL: {
      draft.showLevelPhaseModal = data;

      break;
    }

    case types.SET_SHOW_RULE_ROLL_MODAL: {
      draft.showRuleRollModal = data;

      break;
    }

    case types.RESTART_GAME: {
      return initialState;
    }

    case types.RESTORE_SETTINGS: {
      draft.numberOfPlayers = data.numberOfPlayers;
      draft.difficulty = data.difficulty;
      draft.initialRuleCount = data.initialRuleCount;
      draft.phaseRuleCount = data.phaseRuleCount;
      draft.showIncludedSkills = data.showIncludedSkills;
      draft.showActiveSkills = data.showActiveSkills;
      draft.showPassiveSkills = data.showPassiveSkills;

      break;
    }

    case types.SHOW_SESSION_TOKEN: {
      draft.showSessionToken = data;

      break;
    }

    case types.SHOW_SESSION_MENU: {
      draft.showSessionMenu = data;

      break;
    }

    case types.SHOW_RESTART_GAME_MODAL: {
      draft.showRestartGameModal = data;

      break;
    }

    case types.SET_SAVE_TO_HALL_OF_FAME: {
      draft.saveToHallOfFame = data;

      break;
    }

    case types.SET_HALL_TO_FAME_COMMENT: {
      draft.hallToFameComment = data;

      break
    }

    case types.SET_SHOW_ADVANCED_SETTINGS: {
      draft.showAdvancedSettings = data;

      break
    }

    case types.SET_USE_STREAMER_LAYOUT: {
      draft.useStreamerLayout = data;

      break
    }

    case types.SET_GROUP_RULES: {
      draft.groupRules = data;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
