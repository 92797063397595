import React from 'react';
import { useSelector } from 'react-redux';
import Rule from './rule';
import BaseContainer from '../../baseContainer';
import Title from '../../baseComponents/Title';
import { getNeededTokensToRoll } from '../../../redux/Rules/selectors';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { ruleTypes } from '../../../../functions/nextPhase/data/types/rules';

const RuleCategory = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${colors.secondaryText};
  margin-top: 6px;
  margin-left: 12px;
`;

const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const RulesContainer = () => {
  const {
    game: { players, controlledPlayer, tokens },
    settings: { useStreamerLayout, groupRules = false },
  } = useSelector((state) => state);
  const { rules } = players[controlledPlayer];
  const rollCost = useSelector((state) => getNeededTokensToRoll(state));

  const sortedRules = [...rules].sort((a, b) => {
    const groupA = a.type.toUpperCase();
    const groupB = b.type.toUpperCase();

    if (groupA < groupB) return -1;
    if (groupA > groupB) return 1;

    return 0;
  });

  const ruleArray = groupRules ? sortedRules : rules;
  let category = '';

  const Rules = ruleArray.map((rule) => {
    const showCategory = groupRules && category !== rule.type;
    if (rule.type !== category) category = rule.type;

    return (
      <RuleContainer key={rule.id}>
        {showCategory && <RuleCategory>{ruleTypes[rule.type]}</RuleCategory>}
        <Rule
          canRoll={rollCost <= tokens}
          rule={rule}
          useStreamerLayout={useStreamerLayout}
        />
      </RuleContainer>
    );
  });
  if (useStreamerLayout) {
    return (
      <div>
        <Title text="Phase rules" />
        {Rules}
      </div>
    );
  }
  return (
    <BaseContainer flexDirection="column">
      <Title text="Phase rules" />
      {Rules}
    </BaseContainer>
  );
};

export default RulesContainer;
