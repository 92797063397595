const GEAR_RULE = 'GEAR_RULE';
const GEAR_STAT_RULE = 'GEAR_STAT_RULE';
const GENERAL_RULE = 'GENERAL_RULE';
const USABLES_RULE = 'USABLES_RULE';

const ruleClasses = {
  gearRule: GEAR_RULE,
  gearStatRule: GEAR_STAT_RULE,
  generalRule: GENERAL_RULE,
  usablesRule: USABLES_RULE,
};

const ruleClassesArray = [
  ruleClasses.gearRule,
  ruleClasses.gearStatRule,
  ruleClasses.generalRule,
  ruleClasses.usablesRule,
];

const ruleTypes = {
  GEAR_RULE: 'Gear piece rules',
  GEAR_STAT_RULE: 'Gear stat rules',
  GENERAL_RULE: 'General rules',
  USABLES_RULE: 'Items and Usables rules'
}

module.exports = { ruleClassesArray, ruleClasses, ruleTypes };
