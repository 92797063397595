import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions } from '../../../redux/Settings';
import BaseSelector from '../../baseSelector';

const PlayerCountSelector = () => {
  const {
    settings: { numberOfPlayers },
    user: { sessionToken },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleChange = (data) => {
    dispatch(SettingsActions.setNumberOfPlayers(data.target.value));
  };

  const options = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
  ];

  const isDisabled = () => {
    return sessionToken !== '';
  };

  return (
    <BaseSelector
      options={options}
      label="Player count"
      id="PlayerCountSelector"
      onChange={handleChange}
      value={numberOfPlayers}
      disabled={isDisabled()}
    />
  );
};

export default PlayerCountSelector;
