import colors from '../../../utils/colors';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;

const Group = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const SecondaryText = styled.span`
  font-size: 16px;
  color: ${colors.secondaryText};
`;

const PrimaryText = styled.span`
  font-size: 16px;
  color: ${colors.primaryText};
`;

const TokenInfo = () => {
  const { tokens, rulesRerollCount } = useSelector((state) => state.game);
  return (
    <Container>
      <Group>
        <FontAwesomeIcon
          icon={faCoins}
          size="24"
          color={colors.secondaryText}
        />
        <SecondaryText>{tokens}</SecondaryText>
      </Group>
      <Group>
        <PrimaryText>Roll cost:</PrimaryText>
        <SecondaryText>{rulesRerollCount * 10 + 10}</SecondaryText>
      </Group>
    </Container>
  );
};

export default TokenInfo;
