import types from './types';

const createSession = (data) => ({
  type: types.CREATE_SESSION,
  data,
});

const loadSession = (data) => ({
  type: types.LOAD_SESSION,
  data,
});

const setSessionToken = (data) => ({
  type: types.SET_SESSION_TOKEN,
  data,
});

const setSessionName = (data) => ({
  type: types.SET_SESSION_NAME,
  data,
});

const exitSession = (data) => ({
  type: types.EXIT_SESSION,
  data,
});

const clearSession = (data) => ({
  type: types.CLEAR_SESSION,
  data,
});

const setShowLoginModal = (data) => ({
  type: types.SET_SHOW_LOGIN_MODAL,
  data,
});

const setNewSessionName = (data) => ({
  type: types.SET_NEW_SESSION_NAME,
  data,
});

const setLoadSessionInput = (data) => ({
  type: types.SET_LOAD_SESSION_INPUT,
  data,
});

const restoreUser = (data) => ({
  type: types.RESTORE_USER,
  data,
});

const restartGame = (data) => ({
  type: types.RESTART_GAME,
  data,
});

export default {
  createSession,
  loadSession,
  setSessionToken,
  setSessionName,
  exitSession,
  clearSession,
  setShowLoginModal,
  setNewSessionName,
  setLoadSessionInput,
  restoreUser,
  restartGame,
};
