const RULE_FIRESTRORM = 'RULE_FIRESTRORM';
const RULE_NO_WEREWOLF = 'RULE_NO_WEREWOLF';
const RULE_NO_LYCANTROPHY = 'RULE_NO_LYCANTROPHY';
const RULE_NO_RAVEN = 'RULE_NO_RAVEN';
const RULE_NO_POISON_CREEPER = 'RULE_NO_POISON_CREEPER';

const RULE_NO_MOLTEN_BOULDER = 'RULE_NO_MOLTEN_BOULDER';
const RULE_NO_ARCTIC_BLAST = 'RULE_NO_ARCTIC_BLAST';
const RULE_WEREBEAR = 'RULE_WEREBEAR';
const RULE_NO_OAK_SAGE = 'RULE_NO_OAK_SAGE';
const RULE_NO_SUMMON_SPIRIT_WOLF = 'RULE_NO_SUMMON_SPIRIT_WOLF';

const RULE_NO_FISSURE = 'RULE_NO_FISSURE';
const RULE_NO_CYCLONE_ARMOR = 'RULE_NO_CYCLONE_ARMOR';
const RULE_NO_FERAL_RAGE = 'RULE_NO_FERAL_RAGE';
const RULE_NO_MAUL = 'RULE_NO_MAUL';
const RULE_NO_CARRION_WINE = 'RULE_NO_CARRION_WINE';

const RULE_NO_TWISTER = 'RULE_NO_TWISTER';
const RULE_NO_RABIES = 'RULE_NO_RABIES';
const RULE_NO_FIRE_CLAWS = 'RULE_NO_FIRE_CLAWS';
const RULE_NO_HEART_OF_WOLVERINE = 'RULE_NO_HEART_OF_WOLVERINE';
const RULE_NO_SUMMON_DIRE_WOLF = 'RULE_NO_SUMMON_DIRE_WOLF';

const RULE_NO_VOLCANO = 'RULE_NO_VOLCANO';
const RULE_NO_TORNADO = 'RULE_NO_TORNADO';
const RULE_NO_HUNGER = 'RULE_NO_HUNGER';
const RULE_NO_SHOCK_WAVE = 'RULE_NO_SHOCK_WAVE';
const RULE_NO_SOLAR_CREEPER = 'RULE_NO_SOLAR_CREEPER';

const RULE_NO_ARMAGEDDON = 'RULE_NO_ARMAGEDDON';
const RULE_NO_HURRICANE = 'RULE_NO_HURRICANE';
const RULE_NO_FURY = 'RULE_NO_FURY';
const RULE_NO_SPRIRIT_OF_BARBS = 'RULE_NO_SPRIRIT_OF_BARBS';
const RULE_NO_SUMMON_GRIZZLY = 'RULE_NO_SUMMON_GRIZZLY';

const druidRules = [
  {
    id: RULE_FIRESTRORM,
    name: 'Without assigning points to Firestorm skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_MOLTEN_BOULDER,
      RULE_NO_FISSURE,
      RULE_NO_VOLCANO,
      RULE_NO_ARMAGEDDON,
    ],
  },
  {
    id: RULE_NO_WEREWOLF,
    name: 'Without assigning points to Werewolf skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_LYCANTROPHY,
      RULE_NO_FERAL_RAGE,
      RULE_NO_RABIES,
      RULE_NO_FIRE_CLAWS,
      RULE_NO_HUNGER,
      RULE_NO_FURY,
    ],
  },
  {
    id: RULE_NO_LYCANTROPHY,
    name: 'Without assigning points to Lycantrophy skill',
    level: 1,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_RAVEN,
    name: 'Without assigning points to Raven skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_SUMMON_SPIRIT_WOLF,
      RULE_NO_SUMMON_DIRE_WOLF,
      RULE_NO_SUMMON_GRIZZLY,
    ],
  },
  {
    id: RULE_NO_POISON_CREEPER,
    name: 'Without assigning points to Poison Creeper skill',
    level: 1,
    deactivatesSkills: [RULE_NO_CARRION_WINE, RULE_NO_SOLAR_CREEPER],
  },
  {
    id: RULE_NO_MOLTEN_BOULDER,
    name: 'Without assigning points to Molten Boulder skill',
    level: 6,
    deactivatesSkills: [RULE_NO_FISSURE, RULE_NO_VOLCANO, RULE_NO_ARMAGEDDON],
  },
  {
    id: RULE_NO_ARCTIC_BLAST,
    name: 'Without assigning points to Arctic Blast skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_CYCLONE_ARMOR,
      RULE_NO_TWISTER,
      RULE_NO_TORNADO,
      RULE_NO_HURRICANE,
    ],
  },
  {
    id: RULE_WEREBEAR,
    name: 'Without assigning points to Werebear skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_MAUL,
      RULE_NO_FIRE_CLAWS,
      RULE_NO_HUNGER,
      RULE_NO_SHOCK_WAVE,
    ],
  },
  {
    id: RULE_NO_OAK_SAGE,
    name: 'Without assigning points to Oak Sage skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_HEART_OF_WOLVERINE,
      RULE_NO_SUMMON_DIRE_WOLF,
      RULE_NO_SUMMON_GRIZZLY,
      RULE_NO_SPRIRIT_OF_BARBS,
    ],
  },
  {
    id: RULE_NO_SUMMON_SPIRIT_WOLF,
    name: 'Without assigning points to Summon Spirit Wolf skill',
    level: 6,
    deactivatesSkills: [RULE_NO_SUMMON_DIRE_WOLF, RULE_NO_SUMMON_GRIZZLY],
  },
  {
    id: RULE_NO_FISSURE,
    name: 'Without assigning points to Fissure skill',
    level: 12,
    deactivatesSkills: [RULE_NO_VOLCANO, RULE_NO_ARMAGEDDON],
  },
  {
    id: RULE_NO_CYCLONE_ARMOR,
    name: 'Without assigning points to Cyclone Armor skill',
    level: 12,
    deactivatesSkills: [RULE_NO_TWISTER, RULE_NO_TORNADO, RULE_NO_HURRICANE],
  },
  {
    id: RULE_NO_FERAL_RAGE,
    name: 'Without assigning points to Feral Rage skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_RABIES,
      RULE_NO_FIRE_CLAWS,
      RULE_NO_HUNGER,
      RULE_NO_FURY,
    ],
  },
  {
    id: RULE_NO_MAUL,
    name: 'Without assigning points to Maul skill',
    level: 12,
    deactivatesSkills: [RULE_NO_FIRE_CLAWS, RULE_NO_HUNGER, RULE_NO_SHOCK_WAVE],
  },
  {
    id: RULE_NO_CARRION_WINE,
    name: 'Without assigning points to Carrion Wine skill',
    level: 12,
    deactivatesSkills: [RULE_NO_SOLAR_CREEPER],
  },
  {
    id: RULE_NO_TWISTER,
    name: 'Without assigning points to Twister skill',
    level: 18,
    deactivatesSkills: [RULE_NO_TORNADO, RULE_NO_HURRICANE],
  },
  {
    id: RULE_NO_RABIES,
    name: 'Without assigning points to Rabies skill',
    level: 18,
    deactivatesSkills: [RULE_NO_FURY],
  },
  {
    id: RULE_NO_FIRE_CLAWS,
    name: 'Without assigning points to Fire Claws skill',
    level: 18,
    deactivatesSkills: [RULE_NO_HUNGER],
  },
  {
    id: RULE_NO_HEART_OF_WOLVERINE,
    name: 'Without assigning points to Heart of Wolverine skill',
    level: 18,
    deactivatesSkills: [RULE_NO_SPRIRIT_OF_BARBS],
  },
  {
    id: RULE_NO_SUMMON_DIRE_WOLF,
    name: 'Without assigning points to Summon Dire Wolf skill',
    level: 18,
    deactivatesSkills: [RULE_NO_SUMMON_GRIZZLY],
  },
  {
    id: RULE_NO_VOLCANO,
    name: 'Without assigning points to Volcano skill',
    level: 24,
    deactivatesSkills: [RULE_NO_ARMAGEDDON],
  },
  {
    id: RULE_NO_TORNADO,
    name: 'Without assigning points to Tornado skill',
    level: 24,
    deactivatesSkills: [RULE_NO_HURRICANE],
  },
  {
    id: RULE_NO_HUNGER,
    name: 'Without assigning points to Hunger skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SHOCK_WAVE,
    name: 'Without assigning points to Shock Wave skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SOLAR_CREEPER,
    name: 'Without assigning points to Solar Creeper skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_ARMAGEDDON,
    name: 'Without assigning points to Armageddon skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_HURRICANE,
    name: 'Without assigning points to Hurricane skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FURY,
    name: 'Without assigning points to Fury skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SPRIRIT_OF_BARBS,
    name: 'Without assigning points to Spirit of Barbs skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SUMMON_GRIZZLY,
    name: 'Without assigning points to Summon Grizzly skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = druidRules;
