import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPlayedClass } from '../../../redux/Game/selectors';
import colors from '../../../utils/colors';

const Container = styled.div`
  display: flex;
  width: 100%;
  color: ${colors.secondaryText};
  font-size: 16px;
  align-items: center;
  justify-content: center;
`;

const Character = () => {
  const playedClass = useSelector(getPlayedClass);
  return <Container>{playedClass?.displayName}</Container>;
};

export default Character;
