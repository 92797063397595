import types from './types';

const setNumberOfPlayers = (data) => ({
  type: types.SET_NUMBER_OF_PLAYERS,
  data,
});

const setDifficulty = (data) => ({
  type: types.SET_DIFFICULTY,
  data,
});

const setShowIncludedSkills = (data) => ({
  type: types.SET_SHOW_INCLUDED_SKILLS,
  data,
});

const setShowActiveSkills = (data) => ({
  type: types.SET_SHOW_ACTIVE_SKILLS,
  data,
});

const setShowPassiveSkills = (data) => ({
  type: types.SET_SHOW_PASSIVE_SKILLS,
  data,
});

const setShowPhaseModal = (data) => ({
  type: types.SET_SHOW_PHASE_MODAL,
  data,
});

const setShowLevelPhaseModal = (data) => ({
  type: types.SET_SHOW_LEVEL_PHASE_MODAL,
  data,
});

const setShowRuleRollModal = (data) => ({
  type: types.SET_SHOW_RULE_ROLL_MODAL,
  data,
});

const restoreSettings = (data) => ({
  type: types.RESTORE_SETTINGS,
  data,
});

const restartGame = (data) => ({
  type: types.RESTART_GAME,
  data,
});

const setShowSessionToken = (data) => ({
  type: types.SHOW_SESSION_TOKEN,
  data,
});

const setShowSessionMenu = (data) => ({
  type: types.SHOW_SESSION_MENU,
  data,
});

const setShowRestartGameModal = (data) => ({
  type: types.SHOW_RESTART_GAME_MODAL,
  data,
})

const setSaveToHallOfFame = (data) => ({
  type: types.SET_SAVE_TO_HALL_OF_FAME,
  data,
})

const setHallToFameComment = (data) => ({
  type: types.SET_HALL_TO_FAME_COMMENT,
  data,
})

const setShowAdvancedSettings = (data) => ({
  type: types.SET_SHOW_ADVANCED_SETTINGS,
  data
})

const setUseStreamerLayout = (data) => ({
  type: types.SET_USE_STREAMER_LAYOUT,
  data
})

const setGroupRules = (data) => ({
  type: types.SET_GROUP_RULES,
  data
})
setGroupRules

export default {
  setNumberOfPlayers,
  setDifficulty,
  setShowIncludedSkills,
  setShowActiveSkills,
  setShowPassiveSkills,
  setShowPhaseModal,
  setShowLevelPhaseModal,
  setShowRuleRollModal,
  restoreSettings,
  restartGame,
  setShowSessionToken,
  setShowSessionMenu,
  setShowRestartGameModal,
  setSaveToHallOfFame,
  setHallToFameComment,
  setShowAdvancedSettings,
  setUseStreamerLayout,
  setGroupRules,
};
