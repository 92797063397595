import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GameActions } from '../../../redux/Game';
import colors from '../../../utils/colors';
import BaseButton from '../../baseButton';
import Character from './Character';
import ControlledPlayerSelector from './ControlledPlayerSelector';
import CurrentPhase from './CurrentPhase';
import NextPhaseButton from './NextPhaseButton';
import PhaseGoals from './PhaseGoals';
import RulesContainer from './rulesContainer';
import SkillRules from './SkillRules';
import TokenInfo from './TokenInfo';
import Score from './Score';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}px;
  flex-direction: row;
  background-color: ${colors.grey[1]};
`;

const StreamWindow = styled.div`
  display: flex;
  flex: 8.5;
  width: 100%;
  background-color: #00ff00;
  border: 3px solid ${colors.primaryText};
  border-right: 0px;
  border-radius: 12px;
`;

const TopFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0.75;
  width: 100%;
  background-color: ${colors.grey[1]};
  align-items: center;
  gap: 16px;
`;

const BottomFooter = styled(TopFooter)`
  justify-content: flex-end;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8.5;
`;

const Text = styled.div`
  color: ${colors.primaryText};
  font-size: 24px;
`;

const getHeightFromWidth = (width) => {
  return (width / 16) * 9;
};

const getMaxWidthForSideBar = (width) => {
  return width * 0.15;
};

const StreamerLayout = () => {
  const [width, setWidth] = useState('');
  const dispatch = useDispatch();

  const {
    game: { players, controlledPlayer },
  } = useSelector((state) => state);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  const lvlGapReached = players[controlledPlayer].lastLevelGapReached;
  const nextLevelGap = lvlGapReached === 1 ? 6 : lvlGapReached + 6;

  return (
    <Container height={getHeightFromWidth(width)}>
      <SideBar maxWidth={getMaxWidthForSideBar(width)}>
        <TokenInfo />
        <ControlledPlayerSelector />
        <Character />
        <RulesContainer />
        <SkillRules />
      </SideBar>
      <RightContainer>
        <TopFooter>
          {players[controlledPlayer].lastLevelGapReached === 0 ? (
            <BaseButton
              size="16"
              text="Roll first Skill Rule"
              onClick={() => dispatch(GameActions.nextLevelGapReached())}
            />
          ) : nextLevelGap <= 30 ? (
            <BaseButton
              size="16"
              text={'Level ' + nextLevelGap + ' reached'}
              onClick={() => dispatch(GameActions.nextLevelGapReached())}
            />
          ) : null}
          <NextPhaseButton />
          <Text>Phase:</Text>
          <CurrentPhase />
          <Text>Goals:</Text>
          <PhaseGoals />
        </TopFooter>
        <StreamWindow />
        <BottomFooter>
          <Score />
        </BottomFooter>
      </RightContainer>
    </Container>
  );
};

export default StreamerLayout;
