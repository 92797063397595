import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGa from 'react-ga';
import colors from '../../utils/colors';
import BaseButton from '../baseButton';

const Title = styled.h1`
  font-size: 4em;
  margin-top: 6rem;
  align-self: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const MainContainer = styled.div`
  background-color: ${colors.grey[1]};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 150px;
`;

const StartButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.primaryText};

  &:h5 {
    margin-bottom: 20px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 650px;
  margin-top: 100px;
  color: ${colors.primaryText};
`;

const Header = styled.h2`
  max-width: 400px;
  margin-bottom: 30px;
`;

const MiniHeader = styled.h4`
  max-width: 400px;
  margin-bottom: 15px;
  margin-top: 20px;
`;

const Landing = () => {
  const navigate = useNavigate();

  const startGame = () => {
    ReactGa.event({
      category: 'Play',
      action: 'Navigated to /play',
    });
    const path = 'play';
    navigate(path);
  };

  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const isStarted = game?.currentPhase && game?.currentPhase > 0;
  const ButtonText = isStarted ? 'Continue' : 'Start';

  return (
    <MainContainer>
      <Title>Diablo 2 Challenge Runs</Title>
      <StartButtonContainer>
        {isStarted && (
          <TextContainer>
            You have a session "{user.sessionName}" in progress.
          </TextContainer>
        )}
        <BaseButton onClick={startGame} text={ButtonText} />
      </StartButtonContainer>
      <TextContainer>
        <Header>
          Challenge yourself with or without your friends with randomized
          playthroughs challenges
        </Header>
        <p></p>
      </TextContainer>
      <TextContainer>
        <Header>Base rules and guidelines</Header>
        <p>
          Here are some basic rules that applies to all games played with this
          app. Play by the rules, or find your own way to use this app.
          it&apos;s all about having fun after all.
        </p>
        <MiniHeader>Self-found</MiniHeader>
        <p>
          Like many other challenge playthroughs in Diablo 2, this one is also
          meant to be played as self-found. Start fresh characters and do not
          take gold or gear from other playthrough characters.
        </p>
        <MiniHeader>Hardcore or softcore</MiniHeader>
        <p>
          You can play this challenge in softcore or in hardcore. Of course in
          hardcore the game is at is best. Hope for suitable rules and fight
          your way through normal, nightmare and hell for the ultimate bragging
          rights.
        </p>
        <MiniHeader>Phases and Phase rules</MiniHeader>
        <p>
          The game is played on total of 15 phases. Each act is one phase, and
          completing an act always gives you 10 tokens. You can complete
          additional phase goals to get even more tokens. Tokens can be used to
          reroll your rules. Your team will share the tokens, so choose wisely
          witch rules to reroll. Rolling gets more expensive each time.
        </p>
        <MiniHeader>Skill rules</MiniHeader>
        <p>
          In addition to phase rules, there are also skill rules. Skill rules
          need to be rolled on levels 1, 6, 12, 18, 24 and 30. It always
          prevents you from assigning skill points to certain skill, so be sure
          to roll the rule before assigning any points to new unlocked skills.
          You have one time opportunity to try and reroll skill rules while the
          window is open, and once you close the window, the opportunity is
          gone. As you reroll a skill rule, it will reroll it entirely, which
          means, if you are in a bad luck, you can get the same skill back that
          you tried to roll away. Good luck!
        </p>
      </TextContainer>
      <TextContainer>
        <Header>Feedback is most welcome</Header>
        <p>
          If you find bugs on the site or want to give us feedback about this
          minigame, please contact d3roguelite(at)gmail.com or join our{' '}
          <a href="https://discord.gg/AdV9eJ9">Discord</a> server. Although it
          is a D3roguelite server, there is a specific channel for
          d2challengeRuns.
        </p>
      </TextContainer>
      <TextContainer>
        <Header>Future updates and what we are working on</Header>
        <p>
          The mini game is in semi-constant development. I will try to post
          latest updates on our discord server
        </p>
      </TextContainer>
    </MainContainer>
  );
};

export default Landing;
