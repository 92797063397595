import axios from 'axios';

// eslint-disable-next-line consistent-return
const createSession = async (json) => {
  try {
    const response = await axios.post(
      '/.netlify/functions/createSession',
      json,
    );

    if (response.status === 200) {
      return response.data.sessionToken;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// eslint-disable-next-line consistent-return
const loadSession = async (sessionToken) => {
  try {
    const response = await axios.get('/.netlify/functions/loadSession', {
      params: { sessionToken },
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const nextPhase = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/nextPhase', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const nextLevelGap = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/nextLevelGap', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const getAvailableSkillRuleCount = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/getAvailableSkillRuleCount', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const rerollLastPlayerSkillRule = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/rerollLevelRule', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const rerollRule = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/rerollRule', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const selectRolledRule = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/selectRolledRule', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

const restartGame = async (sessionToken) => {
  try {
    const response = await axios.put(
      '/.netlify/functions/restartGame',
      sessionToken,
    );
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    return err;
  }
};

const startGame = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/startGame', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    return err;
  }
};

const togglePhaseGoal = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/togglePhaseGoal', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    return err;
  }
};

const createHallOfFameEntry = async (payload) => {
  try {
    const response = await axios.put('/.netlify/functions/createHallOfFameEntry', {
      payload,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    return err;
  }
}

export default {
  createSession,
  loadSession,
  nextPhase,
  nextLevelGap,
  rerollRule,
  selectRolledRule,
  restartGame,
  startGame,
  togglePhaseGoal,
  getAvailableSkillRuleCount,
  rerollLastPlayerSkillRule,
  createHallOfFameEntry,
};
