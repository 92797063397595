import produce from 'immer';
import types from './types';
import initialState from './initialState';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.ADD_SESSION: {
      const index = draft.sessions.findIndex(
        (s) => s.sessionToken === data.sessionToken,
      );
      if (index < 0 && data.sessionToken !== '' && data.sessionName !== '') {
        draft.sessions.push(data);
      }

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
