import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

const Label = styled.label`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 4px;
  padding: ${({ useStreamerLayout }) => (useStreamerLayout ? '0' : '0.5rem')};
  margin-bottom: ${({ useStreamerLayout }) =>
    useStreamerLayout ? '0' : '0.5rem'};
  justify-content: flex-start;
  align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  width: 16px;
  height: 18px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: #d2c8ae;
  stroke-width: 3px;
  height: 16px;
  width: 16px;
  position: relative;
  top: -6px;
`;

const Checkbox = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? '#863E10' : 'none')};
  border-radius: 3px;
  border: 1px solid #d2c8ae;
  transition: all 150ms;
  align-items: center;
  justify-content: center;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px #5c929f;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Text = styled.span`
  flex: 1;
  margin-left: 1em;
  color: #d2c8ae;
  font-size: 14px;
`;

const PhaseGoal = ({ phaseGoal, onChange, disabled, useStreamerLayout }) => {
  const phaseGoalText =
    phaseGoal.tokens > 0
      ? phaseGoal.displayName + ' (+' + phaseGoal.tokens + 'T)'
      : phaseGoal.displayName;
  return (
    <Label useStreamerLayout={useStreamerLayout}>
      <HiddenCheckbox
        checked={phaseGoal.done}
        onChange={onChange}
        disabled={disabled}
      />
      <Checkbox checked={phaseGoal.done} disabled={disabled}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkbox>
      <Text title={phaseGoal.description || phaseGoalText}>
        {`${phaseGoal.displayName} +${phaseGoal.tokens} `}
        <FontAwesomeIcon icon={faCoins} />
      </Text>
    </Label>
  );
};

PhaseGoal.propTypes = {
  phaseGoal: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PhaseGoal;
