import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GameActions from '../../../redux/Game/actions';
import { classes } from '../../../../functions/nextPhase/data/types/classes';
import BaseSelector from '../../baseSelector';
import BaseInput from '../../baseInput';

const Player = styled.span`
  display: flex;
  align-self: center;
  margin-top: 20px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

const CharacterSelector = ({ player, value, classValue }) => {
  const dispatch = useDispatch();

  const handleChange = (data) => {
    dispatch(
      GameActions.setPlayedClass({ player, playedClass: data.target.value }),
    );
  };

  const handleNameChange = (data) => {
    dispatch(
      GameActions.setDisplayName({ player, displayName: data.target.value }),
    );
  };

  const label = player + 1;

  const classOptions = Object.keys(classes).map((key) => {
    return { name: classes[key]?.displayName, value: key };
  });
  const allOptions = [{ name: 'Random', value: 'RANDOM' }].concat(classOptions);

  return (
    <Container>
      <Player>{label}</Player>
      <BaseInput
        label="Player name"
        onChange={handleNameChange}
        width="200px"
        nomargin
        value={value}
      />
      <BaseSelector
        options={allOptions}
        label="Class"
        id={'P' + player + 'characterSelector'}
        onChange={handleChange}
        value={classValue}
      />
    </Container>
  );
};

CharacterSelector.propTypes = {
  player: PropTypes.number,
  value: PropTypes.string,
  classValue: PropTypes.string,
};

export default CharacterSelector;
