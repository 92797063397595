import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../utils/colors';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const { useSelector } = require('react-redux');
const { selectScore } = require('../../../redux/Game/selectors');
const { default: styled } = require('styled-components');

const ScoreContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const Text = styled.span`
  color: ${colors.primaryText};
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`;

const Score = () => {
  const score = useSelector(selectScore);
  return (
    <ScoreContainer>
      <FontAwesomeIcon icon={faStar} size="24" color={colors.primaryText} />
      <Text>Score: {score}</Text>
    </ScoreContainer>
  );
};

export default Score;
