import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Title from '../../baseComponents/Title';
import BaseButton from '../../baseButton';
import Checkbox from './Checkbox';
import InputField from '../../baseInput';
import LoadingSpinner from './LoadingSpinner';
import { SettingsActions } from '../../../redux/Settings';
import { GameActions } from '../../../redux/Game';
import colors from '../../../utils/colors';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding-bottom: 24px;
  width: 60%;
  max-width: 650px;
  border: 2px solid ${colors.grey[3]};
  border-radius: 8px;
  background-color: ${colors.grey[1]};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const Change = styled.p`
  font-size: 22px;
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  margin-top: 30px;
`;

const PhaseText = styled.p`
  margin: 30px auto 10px auto;
  font-size: 12px;
  width: 250px;
  color: ${colors.secondaryText};
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
`;

const ModalButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 70px;
  margin-right: 70px;
`;

const CommentInput = styled.textarea`
  border-radius: 4px;
  border: 1px solid rgb(118, 118, 118);
  background-color: #444444;
  color: #ffffff;
  width: 30rem;
  height: 5rem;
  margin: 5px auto 25px auto;
`;

const Label = styled.label`
  margin-bottom: 0px;
  text-align: center;
  font-size: 1rem;
`;

const LevelGapModal = () => {
  const dispatch = useDispatch();

  const { saveToHallOfFame, hallToFameComment } = useSelector(
    (state) => state.settings,
  );
  const handleOnChangeHallOfFame = () => {
    dispatch(SettingsActions.setSaveToHallOfFame(!!!saveToHallOfFame));
  };

  const handleOnChangeComment = (event) => {
    dispatch(SettingsActions.setHallToFameComment(event.target?.value));
  };

  let restartGameButtonText = 'Restart progress';
  restartGameButtonText += saveToHallOfFame ? ' and add to Hall of Fame' : '';

  return (
    <Container>
      <Modal>
        <VerticalContainer>
          <Title text="Restart Game" />
          <SmallTitle>Hall Of Fame?</SmallTitle>
          <PhaseText>
            You can save this playthrough to hall of fame. It will be available
            for every one to see the rules and how far you reached. Add a link
            to a video of the gameplay to below field if you want to get this
            run verified by others. Verified runs will be shown on their own
            leaderboard. GUI for Hall of Fame is still in the making, but you
            can add this game for later use and to help of the development.
          </PhaseText>
          <Checkbox
            width="250px"
            checked={!!saveToHallOfFame}
            onChange={handleOnChangeHallOfFame}
            title="Add game to hall of fame"
            label="Add game to hall of fame"
          />
          <Label>Comment</Label>
          <CommentInput
            value={hallToFameComment}
            onChange={handleOnChangeComment}
          />
          <ModalButtonContainer>
            <BaseButton
              size="18"
              text={restartGameButtonText}
              onClick={() => dispatch(GameActions.restartGame())}
            />
          </ModalButtonContainer>
        </VerticalContainer>
      </Modal>
    </Container>
  );
};

export default LevelGapModal;
