import {
  fork,
  takeEvery,
  put,
  select,
  call,
  takeLatest,
} from 'redux-saga/effects';
import types from './types';
import UserActions from '../User/actions';
import RulesActions from '../Rules/actions';
import GameActions from './actions';
import { SettingsActions } from '../Settings';
import { SessionsActions } from '../Sessions';

function* handleNextPhase(context) {
  const { ReactGa, apiService } = context;
  yield put(GameActions.setPhaseLoading(true));

  const {
    user: { sessionToken },
    settings: { difficulty, numberOfPlayers },
    game,
  } = yield select((state) => state);

  const { currentPhase } = game;
  yield put(SettingsActions.setShowPhaseModal(true));
  try {
    const state = yield call(apiService.nextPhase, {
      sessionToken,
      difficulty,
      oldPhase: currentPhase,
      numberOfPlayers,
    });

    yield put(UserActions.restoreUser(state.user));
    yield put(SettingsActions.restoreSettings(state.settings));
    yield put(GameActions.restoreGame(state.game));
    yield put(RulesActions.restoreRules(state.rules));

    ReactGa.event({
      category: 'Play',
      action: 'Moved to next Phase',
      value: state.game.phase,
    });

    yield put(GameActions.setPhaseLoading(false));
  } catch (e) {
    yield put(GameActions.setPhaseLoading(false));
  }
}

function* handleNextLevelGapReached(context) {
  const { apiService } = context;
  yield put(GameActions.setPhaseLoading(true));

  const {
    user: { sessionToken },
    game: { controlledPlayer },
  } = yield select((state) => state);

  try {
    yield put(SettingsActions.setShowLevelPhaseModal(true));
    const state = yield call(apiService.nextLevelGap, {
      sessionToken,
      player: controlledPlayer,
    });

    yield put(GameActions.restoreGame(state.game));

    const response = yield call(apiService.getAvailableSkillRuleCount,
      {
        sessionToken,
        player: controlledPlayer,
      }
    );

    yield put(GameActions.setAvailableLevelRuleCount(response.availableLevelRuleCount));

    yield put(GameActions.setPhaseLoading(false));
  } catch (e) {
    console.log('error', e)
    yield put(GameActions.setPhaseLoading(false));
  }
}

function* handleRollPlayerLastLevelRule(context) {
  const { apiService } = context;
  yield put(GameActions.setPhaseLoading(true));

  const {
    user: { sessionToken },
    game: { controlledPlayer },
  } = yield select((state) => state);
  try {
    yield put(SettingsActions.setShowLevelPhaseModal(true));
    const state = yield call(apiService.rerollLastPlayerSkillRule, {
      sessionToken,
      player: controlledPlayer,
    });
    yield put(GameActions.restoreGame(state.game));

    const response = yield call(apiService.getAvailableSkillRuleCount,
      {
        sessionToken,
        player: controlledPlayer,
      }
    );

    yield put(GameActions.setAvailableLevelRuleCount(response.availableLevelRuleCount));

    yield put(GameActions.setPhaseLoading(false));
  } catch (e) {
    console.log('error', e)
    yield put(GameActions.setPhaseLoading(false));
  }
}

function* handleStartGame(context) {
  const { ReactGa, apiService } = context;
  yield put(GameActions.setPhaseLoading(true));

  const {
    user: { sessionToken, sessionName },
    settings: { difficulty, numberOfPlayers },
    game,
  } = yield select((state) => state);

  const { players, gameMode } = game;
  const playerSetup = [];
  for (let p = 0; p < numberOfPlayers; p += 1) {
    playerSetup.push({
      playedClass: players[p].playedClass,
      displayName: players[p].displayName,
    });
  }

  const state = yield call(apiService.startGame, {
    sessionToken: sessionToken || 'newGame',
    difficulty,
    playerSetup,
    sessionName,
    gameMode,
  });

  yield put(
    SessionsActions.addSession({
      sessionName,
      sessionToken: state.user.sessionToken,
    }),
  );

  yield put(UserActions.restoreUser(state.user));
  yield put(SettingsActions.restoreSettings(state.settings));
  yield put(GameActions.restoreGame(state.game));
  yield put(RulesActions.restoreRules(state.rules));
  yield put(GameActions.setPhaseLoading(false));

  ReactGa.event({
    category: 'Play',
    action: 'Started a game',
  });

  yield put(UserActions.setShowLoginModal(true));
}

function* handleTogglePhaseGoal(context, { data }) {
  const {
    game: { currentPhase, phaseGoals },
    user: { sessionToken },
  } = yield select((state) => state);

  const { ReactGa, apiService } = context;
  ReactGa.event({
    category: 'Play',
    action: 'Toggled phase goal',
    value: currentPhase,
  });

  const phaseGoal = phaseGoals.find((goal) => goal.id === data.id)

  const { newState } = yield call(apiService.togglePhaseGoal, {
    phaseGoal,
    sessionToken,
  });

  yield put(GameActions.restoreGame(newState.game));
}

function* handleToggleAdditionalPhaseGoal(context, { data }) {
  const {
    game: { phaseGoals },
    user: { sessionToken },
  } = yield select((state) => state);

  const { ReactGa, apiService } = context;
  ReactGa.event({
    category: 'Play',
    action: 'Toggled Additional phase goal',
  });

  const phaseGoal = phaseGoals.find((goal) => goal.id === data.goal.id)

  const { newState } = yield call(apiService.togglePhaseGoal, {
    phaseGoal,
    sessionToken,
  });

  yield put(GameActions.restoreGame(newState.game));
}

function* handleRestartGame(context) {
  const { apiService } = context

  const state = yield select((state) => state)
  const {
    user: { sessionToken },
    settings: { saveToHallOfFame, hallToFameComment }
  } = state
  let saveToHallOfFameResult = null
  if (!!saveToHallOfFame) {
    saveToHallOfFameResult = yield call(apiService.createHallOfFameEntry, {
      game: state.game,
      comment: hallToFameComment
    })
  }
  if (!!saveToHallOfFame && saveToHallOfFameResult || !saveToHallOfFame) {
    const newState = yield call(apiService.restartGame, { sessionToken });
    yield put(UserActions.restoreUser(newState.user));
    yield put(SettingsActions.restoreSettings(newState.settings));
    yield put(GameActions.restoreGame(newState.game));
    yield put(RulesActions.restoreRules(newState.rules));
    yield put(SettingsActions.setShowRestartGameModal(false));
  }
}

// **** WATCHERS ****
function* watchStartGame(context) {
  yield takeEvery(types.START_GAME, handleStartGame, context);
}

function* watchNextPhase(context) {
  yield takeEvery(types.NEXT_PHASE, handleNextPhase, context);
}

function* watchNextLevelGap(context) {
  yield takeLatest(
    types.NEXT_LEVEL_GAP_REACHED,
    handleNextLevelGapReached,
    context,
  );
}

function* watchRollLastLevelRule(context) {
  yield takeLatest(
    types.REROLL_PLAYER_LAST_LEVEL_RULE,
    handleRollPlayerLastLevelRule,
    context,
  )
}

function* watchTogglePhaseGoal(context) {
  yield takeEvery(types.SET_PHASE_GOAL_STATE, handleTogglePhaseGoal, context);
}

function* watchToggleAdditionalPhaseGoal(context) {
  yield takeEvery(types.SET_ADDITIONAL_PHASE_GOAL_STATE, handleToggleAdditionalPhaseGoal, context);
}

function* watchRestartGame(context) {
  yield takeEvery(types.RESTART_GAME, handleRestartGame, context);
}

export default (context) => [
  fork(watchStartGame, context),
  fork(watchNextPhase, context),
  fork(watchNextLevelGap, context),
  fork(watchToggleAdditionalPhaseGoal, context),
  fork(watchRollLastLevelRule, context),
  fork(watchTogglePhaseGoal, context),
  fork(watchRestartGame, context),
];
