import classes from '../../../functions/nextPhase/data/types/classes';

const getPlayedClass = (state) => {
  let controlledPlayer = state.game.controlledPlayer || 0
  if (
    state.game.players[controlledPlayer]?.playedClass !== 'RANDOM'
  ) {
    return classes.classes[
      state.game.players[controlledPlayer]?.playedClass
    ];
  }
  return null;
};

const getPhaseGoalsForCurrentPhase = (state) => {
  return state.game.phaseGoals.filter(
    (goal) => goal.phase === state.game.currentPhase,
  );
};

const allCurrentPhaseGoalsDone = (state) => {
  let doneCount = 0;
  let phaseGoalCount = 0;
  state.game.phaseGoals.forEach((goal) => {
    if (goal.phase === state.game.currentPhase ) {
      phaseGoalCount += 1;
    }
    if (goal.phase === state.game.currentPhase && goal.done) {
      doneCount += 1;
    }
  });
  return doneCount === phaseGoalCount;
};
/**
 * @todo needs to be changed
 */
const getTokensPerPhase = () => {
  return 10;
};

const selectScore = (state) => {
  return state.game.score
}

export {
  getPlayedClass,
  getPhaseGoalsForCurrentPhase,
  allCurrentPhaseGoalsDone,
  getTokensPerPhase,
  selectScore,
};
