const RULE_NO_JAB = 'RULE_JAB';
const RULE_NO_INNER_SIGHT = 'RULE_NO_INNER_SIGHT';
const RULE_NO_CRITICAL_STRIKE = 'RULE_NO_CRITICAL_STRIKE';
const RULE_NO_POWER_STRIKE = 'RULE_NO_POWER_STRIKE';
const RULE_NO_POISON_JAVELIN = 'RULE_NO_POISON_JAVELIN';
const RULE_NO_LIGHTNING_BOLT = 'RULE_NO_LIGHTNING_BOLT';
const RULE_NO_PLAGUE_JAVELIIN = 'RULE_NO_PLAGUE_JAVELIIN';
const RULE_NO_CHARGED_STRIKE = 'RULE_NO_CHARGED_STRIKE';
const RULE_NO_LIGHTNING_STRIKE = 'RULE_NO_LIGHTNING';
const RULE_NO_LIGHTNING_FURY = 'RULE_NO_LIGHTNING_FURY';
const RULE_NO_IMPALE = 'RULE_NO_IMPALE';
const RULE_NO_FEND = 'RULE_NO_FEND';
const RULE_NO_SLOW_MISSILES = 'RULE_NO_SLOW_MISSILES';
const RULE_NO_DECOY = 'RULE_NO_DECOY';
const RULE_NO_VALKYRIE = 'RULE_NO_VALKYRIE';
const RULE_NO_PENETRATE = 'RULE_NO_PENETRATE';
const RULE_NO_PIERCE = 'RULE_NO_PIERCE';
const RULE_NO_DODGE = 'RULE_NO_DODGE';
const RULE_NO_AVOID = 'RULE_NO_AVOID';
const RULE_NO_EVADE = 'RULE_NO_EVADE';
const RULE_NO_FIRE_ARROW = 'RULE_NO_FIRE_ARROW';
const RULE_NO_COLD_ARROW = 'RULE_NO_COLD_ARROW';
const RULE_NO_ICE_ARROW = 'RULE_NO_ICE_ARROW';
const RULE_NO_MAGIC_ARROW = 'RULE_NO_MAGIC_ARROW';
const RULE_NO_MULTIPLESHOT = 'RULE_NO_MULTIPLESHOT';
const RULE_NO_GUIDED_ARROW = 'RULE_NO_GUIDED_ARROW';
const RULE_NO_STRAFE = 'RULE_NO_STRAFE';
const RULE_NO_EXPLODING_ARROW = 'RULE_NO_EXPLODING_ARROW';
const RULE_NO_IMMOLATION_ARROW = 'RULE_NO_IMMOLATION_ARROW';
const RULE_NO_FREEZING_ARROW = 'RULE_NO_FREEZING_ARROW';

const amazonRules = [
  {
    id: RULE_NO_JAB,
    name: 'Without assigning points to Jab skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_POWER_STRIKE,
      RULE_NO_CHARGED_STRIKE,
      RULE_NO_LIGHTNING_STRIKE,
      RULE_NO_IMPALE,
      RULE_NO_FEND,
    ],
  },
  {
    id: RULE_NO_POWER_STRIKE,
    name: 'Without assigning points to Power Strike skill',
    level: 6,
    deactivatesSkills: [RULE_NO_CHARGED_STRIKE, RULE_NO_LIGHTNING_STRIKE],
  },
  {
    id: RULE_NO_POISON_JAVELIN,
    name: 'Without assigning points to Poison Javelin skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_LIGHTNING_BOLT,
      RULE_NO_PLAGUE_JAVELIIN,
      RULE_NO_CHARGED_STRIKE,
      RULE_NO_LIGHTNING_STRIKE,
      RULE_NO_LIGHTNING_FURY,
    ],
  },
  {
    id: RULE_NO_IMPALE,
    name: 'Without assigning points to Impale skill',
    level: 12,
    deactivatesSkills: [RULE_NO_FEND],
  },
  {
    id: RULE_NO_LIGHTNING_BOLT,
    name: 'Without assigning points to Lightning Bolt skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_PLAGUE_JAVELIIN,
      RULE_NO_CHARGED_STRIKE,
      RULE_NO_LIGHTNING_STRIKE,
      RULE_NO_LIGHTNING_FURY,
    ],
  },
  {
    id: RULE_NO_PLAGUE_JAVELIIN,
    name: 'Without assigning points to Plague Javelin skill',
    level: 18,
    deactivatesSkills: [RULE_NO_LIGHTNING_FURY],
  },
  {
    id: RULE_NO_CHARGED_STRIKE,
    name: 'Without assigning points to Charged Strike skill',
    level: 18,
    deactivatesSkills: [RULE_NO_LIGHTNING_STRIKE],
  },
  {
    id: RULE_NO_LIGHTNING_STRIKE,
    name: 'Without assigning points to Lightning Strike skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_LIGHTNING_FURY,
    name: 'Without assigning points to Lightning Fury skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FEND,
    name: 'Without assigning points to Fend skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_INNER_SIGHT,
    name: 'Without assigning points to Inner Sight skill',
    level: 1,
    deactivatesSkills: [RULE_NO_SLOW_MISSILES, RULE_NO_DECOY, RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_SLOW_MISSILES,
    name: 'Without assigning points to Slow Missiles skill',
    level: 12,
    deactivatesSkills: [RULE_NO_DECOY, RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_DECOY,
    name: 'Without assigning points to Decoy skill',
    level: 24,
    deactivatesSkills: [RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_VALKYRIE,
    name: 'Without assigning points to Valkyrie skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_CRITICAL_STRIKE,
    name: 'Without assigning points to Critical Strike skill',
    level: 1,
    deactivatesSkills: [RULE_NO_PENETRATE, RULE_NO_PIERCE],
  },
  {
    id: RULE_NO_PENETRATE,
    name: 'Without assigning points to Penetrate skill',
    level: 18,
    deactivatesSkills: [RULE_NO_PIERCE],
  },
  {
    id: RULE_NO_PIERCE,
    name: 'Without assigning points to Pierce skill',
    level: 30,
    deactivatesSkills: [RULE_NO_PIERCE],
  },
  {
    id: RULE_NO_DODGE,
    name: 'Without assigning points to Dodge skill',
    level: 6,
    deactivatesSkills: [RULE_NO_AVOID, RULE_NO_EVADE, RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_AVOID,
    name: 'Without assigning points to Avoid skill',
    level: 12,
    deactivatesSkills: [RULE_NO_EVADE, RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_EVADE,
    name: 'Without assigning points to Evade skill',
    level: 24,
    deactivatesSkills: [RULE_NO_VALKYRIE],
  },
  {
    id: RULE_NO_MAGIC_ARROW,
    name: 'Without assigning points to Magic Arrow skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_MULTIPLESHOT,
      RULE_NO_GUIDED_ARROW,
      RULE_NO_STRAFE,
      RULE_NO_EXPLODING_ARROW,
      RULE_NO_IMMOLATION_ARROW,
    ],
  },
  {
    id: RULE_NO_FIRE_ARROW,
    name: 'Without assigning points to Fire Arrow skill',
    level: 1,
    deactivatesSkills: [RULE_NO_EXPLODING_ARROW, RULE_NO_IMMOLATION_ARROW],
  },
  {
    id: RULE_NO_COLD_ARROW,
    name: 'Without assigning points to Cold Arrow skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_ICE_ARROW,
      RULE_NO_FREEZING_ARROW,
      RULE_NO_GUIDED_ARROW,
      RULE_NO_STRAFE,
    ],
  },
  {
    id: RULE_NO_ICE_ARROW,
    name: 'Without assigning points to Ice Arrow skill',
    level: 18,
    deactivatesSkills: [RULE_NO_FREEZING_ARROW],
  },
  {
    id: RULE_NO_MULTIPLESHOT,
    name: 'Without assigning points to Multipleshot skill',
    level: 6,
    deactivatesSkills: [RULE_NO_GUIDED_ARROW, RULE_NO_STRAFE],
  },
  {
    id: RULE_NO_GUIDED_ARROW,
    name: 'Without assigning points to Guided Arrow skill',
    level: 18,
    deactivatesSkills: [RULE_NO_STRAFE],
  },
  {
    id: RULE_NO_STRAFE,
    name: 'Without assigning points to Strafe skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_EXPLODING_ARROW,
    name: 'Without assigning points to Exploding Arrow skill',
    level: 12,
    deactivatesSkills: [RULE_NO_IMMOLATION_ARROW],
  },
  {
    id: RULE_NO_IMMOLATION_ARROW,
    name: 'Without assigning points to Immolation Arrow skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FREEZING_ARROW,
    name: 'Without assigning points to Freezing Arrow skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = amazonRules;
