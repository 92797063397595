import types from './types';

const setPlayedClass = (data) => ({
  type: types.SET_PLAYED_CLASS,
  data,
});

const nextPhase = (data) => ({
  type: types.NEXT_PHASE,
  data,
});

const nextLevelGapReached = (data) => ({
  type: types.NEXT_LEVEL_GAP_REACHED,
  data,
});

const updatePlayerSkillRules = (data) => ({
  type: types.UPDATE_PLAYER_SKILL_RULES,
  data,
});

const setAvailableLevelRuleCount = (data) => ({
  type: types.SET_AVAILABLE_LEVEL_RULE_COUNT,
  data,
})

const rerollLastPlayerSkillRule = (data) => ({
  type: types.REROLL_PLAYER_LAST_LEVEL_RULE,
  data,
})

const setPhaseGoalState = (data) => ({
  type: types.SET_PHASE_GOAL_STATE,
  data,
});

const setAdditionalPhaseGoalState = (data) => ({
  type: types.SET_ADDITIONAL_PHASE_GOAL_STATE,
  data,
});

const startGame = (data) => ({
  type: types.START_GAME,
  data,
});

const restartGame = (data) => ({
  type: types.RESTART_GAME,
  data,
});

const restoreGame = (data) => ({
  type: types.RESTORE_GAME,
  data,
});

const resetGame = (data) => ({
  type: types.RESET_GAME,
  data,
});

const setControlledPlayer = (data) => ({
  type: types.SET_CONTROLLED_PLAYER,
  data,
});

const setDisplayName = (data) => ({
  type: types.SET_DISPLAY_NAME,
  data,
});

const changeRule = (data) => ({
  type: types.CHANGE_RULE,
  data,
});

const setPhaseLoading = (data) => ({
  type: types.SET_PHASE_LOADING,
  data,
});

const setUnlockedDifficulties = (data) => ({
  type: types.SET_UNLOCKED_DIFFICULTIES,
  data,
});

export default {
  setPlayedClass,
  nextPhase,
  nextLevelGapReached,
  updatePlayerSkillRules,
  setAvailableLevelRuleCount,
  rerollLastPlayerSkillRule,
  setPhaseGoalState,
  setAdditionalPhaseGoalState,
  startGame,
  restartGame,
  restoreGame,
  resetGame,
  setControlledPlayer,
  setDisplayName,
  changeRule,
  setPhaseLoading,
  setUnlockedDifficulties,
};
