const { ruleClasses } = require('../types/rules');

const RULE = 'RULE_';
const NO_WEAPON_OR_SHIELD_RUNEWORDS = RULE + 'NO_WEAPON_OR_SHIELD_RUNEWORDS';
const NO_ARMOR_PIECE_RUNEWORDS = RULE + 'NO_ARMOR_PIECE_RUNEWORDS';
const NO_UNIQUE_WEAPONS = RULE + 'NO_UNIQUE_WEAPONS';
const NO_UNIQUE_ARMOR = RULE + 'NO_UNIQUE_ARMOR';
const NO_UNIQUE_JEWELRY = RULE + 'NO_UNIQUE_JEWELRY';
const NO_SET_WEAPONS = RULE + 'NO_SET_WEAPONS';
const NO_SET_ARMORS = RULE + 'NO_SET_ARMORS';
const NO_SET_JEWELRY = RULE + 'NO_SET_JEWELRY';
const NO_RARE_WEAPONS = RULE + 'NO_RARE_WEAPONS';
const NO_RARE_ARMORS = RULE + 'NO_RARE_ARMORS';
const NO_RARE_JEWELRY = RULE + 'NO_RARE_JEWELRY';
const NO_MAGICAL_WEAPONS = RULE + 'NO_MAGICAL_WEAPONS';
const NO_MAGICAL_ARMORS = RULE + 'NO_MAGICAL_ARMORS';
const NO_MAGICAL_JEWELRY = RULE + 'NO_MAGICAL_JEWELRY';

const gearPieceRules = [
  {
    id: NO_WEAPON_OR_SHIELD_RUNEWORDS,
    name: 'Without any Weapon or Shield Runewords',
    type: ruleClasses.gearRule,
    multiplier: 1.17,
    extraTokens: 3,
  },
  {
    id: NO_ARMOR_PIECE_RUNEWORDS,
    name: 'Without any Armor Piece Runewords',
    type: ruleClasses.gearRule,
    tooltip: 'Armor pieces are body armor and helm. (and gloves and boots, but those cannot have runewords)',
    multiplier: 1.15,
    extraTokens: 3,
  },
  {
    id: NO_UNIQUE_WEAPONS,
    name: 'Without any Unique Weapons or shields',
    type: ruleClasses.gearRule,
    multiplier: 1,
  },
  {
    id: NO_UNIQUE_ARMOR,
    name: 'Without any Unique Armor Pieces',
    type: ruleClasses.gearRule,
    tooltip: 'Armor pieces are body armors, helms, gloves, boots and belts.',
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_UNIQUE_JEWELRY,
    name: 'Without any Unique Jewelry',
    type: ruleClasses.gearRule,
    tooltip: 'Jewelries are rings and amulets',
    multiplier: 1.04,
  },
  {
    id: NO_SET_WEAPONS,
    name: 'Without any Set Weapons or shields',
    type: ruleClasses.gearRule,
    multiplier: 1,
    extraTokens: 0,
  },
  {
    id: NO_SET_ARMORS,
    name: 'Without any Set Armor Pieces',
    type: ruleClasses.gearRule,
    tooltip: 'Armor pieces are body armors, helms, gloves, boots and belts.',
    multiplier: 1.09,
    extraTokens: 1,
  },
  {
    id: NO_SET_JEWELRY,
    name: 'Without any Set Jewelry',
    type: ruleClasses.gearRule,
    tooltip: 'Jewelries are rings and amulets',
    multiplier: 1.04,
    extraTokens: 0,
  },
  {
    id: NO_RARE_WEAPONS,
    name: 'Without any Rare Weapons or shields',
    type: ruleClasses.gearRule,
    multiplier: 1.09,
    extraTokens: 1,
  },
  {
    id: NO_RARE_ARMORS,
    name: 'Without any Rare Armor Pieces',
    type: ruleClasses.gearRule,
    tooltip: 'Armor pieces are body armors, helms, gloves, boots and belts.',
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_RARE_JEWELRY,
    name: 'Without any Rare Jewelry',
    type: ruleClasses.gearRule,
    tooltip: 'Jewelries are rings and amulets',
    multiplier: 1.07,
    extraTokens: 1,
  },
  {
    id: NO_MAGICAL_WEAPONS,
    name: 'Without any Magical Weapons or shields',
    type: ruleClasses.gearRule,
    multiplier: 1.07,
    extraTokens: 1,
  },
  {
    id: NO_MAGICAL_ARMORS,
    name: 'Without any Magical Armor Pieces',
    type: ruleClasses.gearRule,
    tooltip: 'Armor pieces are body armors, helms, gloves, boots and belts.',
    multiplier: 1.05,
    extraTokens: 0,
  },
  {
    id: NO_MAGICAL_JEWELRY,
    name: 'Without any Magical Jewelry',
    type: ruleClasses.gearRule,
    tooltip: 'Jewelries are rings and amulets',
    multiplier: 1.06,
    extraTokens: 1,
  },
];

module.exports = gearPieceRules;
