const RULE_NO_TIGER_STRIKE = 'RULE_NO_TIGER_STRIKE';
const RULE_NO_DRAGON_TALON = 'RULE_POWER_STRIKE';
const RULE_NO_CLAW_MASTERY = 'RULE_NO_CLAW_MASTERY';
const RULE_NO_PHYSIC_HAMMER = 'RULE_NO_PHYSIC_HAMMER';
const RULE_NO_FIRE_BLAST = 'RULE_NO_FIRE_BLAST';

const RULE_NO_FIST_OF_FIRE = 'RULE_NO_FIST_OF_FIRE';
const RULE_NO_DRAGON_CLAW = 'RULE_NO_DRAGON_CLAW';
const RULE_NO_BURST_OF_SPEED = 'RULE_NO_BURST_OF_SPEED';
const RULE_NO_SHOCK_WEB = 'RULE_NO_SHOCK_WEB';
const RULE_NO_BLADE_SENTINEL = 'RULE_NO_BLADE_SENTINEL';

const RULE_NO_COBRA_STRIKE = 'RULE_NO_COBRA_STRIKE';
const RULE_NO_WEAPON_BLOCK = 'RULE_NO_WEAPON_BLOCK';
const RULE_NO_CLOAK_OF_SHADOWS = 'RULE_NO_CLOAK_OF_SHADOWS';
const RULE_NO_CHARGED_BOLT_SENTRY = 'RULE_NO_CHARGED_BOLT_SENTRY';
const RULE_NO_WAKE_OF_FIRE = 'RULE_NO_WAKE_OF_FIRE';

const RULE_NO_CLAWS_OF_THUNDER = 'RULE_NO_CLAWS_OF_THUNDER';
const RULE_NO_DRAGON_TAIL = 'RULE_NO_DRAGON_TAIL';
const RULE_NO_FADE = 'RULE_NO_FADE';
const RULE_NO_SHADOW_WARRIOR = 'RULE_NO_SHADOW_WARRIOR';
const RULE_NO_BLADE_FURY = 'RULE_NO_BLADE_FURY';

const RULE_NO_BLADES_OF_ICE = 'RULE_NO_BLADES_OF_ICE';
const RULE_NO_DRAGON_FLIGHT = 'RULE_NO_DRAGON_FLIGHT';
const RULE_NO_MIND_BLAST = 'RULE_NO_MIND_BLAST';
const RULE_NO_LIGHTNING_SENTRY = 'RULE_NO_LIGHTNING_SENTRY';
const RULE_NO_WAKE_OF_INFERNO = 'RULE_NO_WAKE_OF_INFERNO';

const RULE_NO_PHOENIX_STRIKE = 'RULE_NO_PHOENIX_STRIKE';
const RULE_NO_VENOM = 'RULE_NO_VENOM';
const RULE_NO_SHADOW_MASTER = 'RULE_NO_SHADOW_MASTER';
const RULE_NO_DEATH_SENTRY = 'RULE_NO_FREEZING_ARROW';
const RULE_NO_BLADE_SHIELD = 'RULE_NO_BLADE_SHIELD';

const assasinRules = [
  {
    id: RULE_NO_TIGER_STRIKE,
    name: 'Without assigning points to Tiger Strike skill',
    level: 1,
    deactivatesSkills: [RULE_NO_COBRA_STRIKE, RULE_NO_PHOENIX_STRIKE],
  },
  {
    id: RULE_NO_DRAGON_TALON,
    name: 'Without assigning points to Dragon Talon skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_DRAGON_CLAW,
      RULE_NO_DRAGON_TAIL,
      RULE_NO_DRAGON_FLIGHT,
    ],
  },
  {
    id: RULE_NO_CLAW_MASTERY,
    name: 'Without assigning points to Claw Mastery skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_BURST_OF_SPEED,
      RULE_NO_WEAPON_BLOCK,
      RULE_NO_FADE,
      RULE_NO_SHADOW_WARRIOR,
      RULE_NO_VENOM,
      RULE_NO_SHADOW_MASTER,
    ],
  },
  {
    id: RULE_NO_PHYSIC_HAMMER,
    name: 'Without assigning points to Physic Hammer skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_CLOAK_OF_SHADOWS,
      RULE_NO_SHADOW_WARRIOR,
      RULE_NO_MIND_BLAST,
      RULE_NO_SHADOW_MASTER,
    ],
  },
  {
    id: RULE_NO_FIRE_BLAST,
    name: 'Without assigning points to Fire Blast skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_SHOCK_WEB,
      RULE_NO_CHARGED_BOLT_SENTRY,
      RULE_NO_WAKE_OF_FIRE,
      RULE_NO_LIGHTNING_SENTRY,
      RULE_NO_WAKE_OF_INFERNO,
      RULE_NO_DEATH_SENTRY,
      RULE_NO_BLADE_FURY,
      RULE_NO_BLADE_SHIELD,
    ],
  },
  {
    id: RULE_NO_FIST_OF_FIRE,
    name: 'Without assigning points to Fist Of Fire skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_CLAWS_OF_THUNDER,
      RULE_NO_BLADES_OF_ICE,
      RULE_NO_PHOENIX_STRIKE,
    ],
  },
  {
    id: RULE_NO_DRAGON_CLAW,
    name: 'Without assigning points to Dragon Claw skill',
    level: 6,
    deactivatesSkills: [RULE_NO_DRAGON_TAIL, RULE_NO_DRAGON_FLIGHT],
  },
  {
    id: RULE_NO_BURST_OF_SPEED,
    name: 'Without assigning points to Burst of speed skill',
    level: 6,
    deactivatesSkills: [RULE_NO_FADE, RULE_NO_VENOM],
  },
  {
    id: RULE_NO_SHOCK_WEB,
    name: 'Without assigning points to Shock Web skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_CHARGED_BOLT_SENTRY,
      RULE_NO_LIGHTNING_SENTRY,
      RULE_NO_DEATH_SENTRY,
    ],
  },
  {
    id: RULE_NO_BLADE_SENTINEL,
    name: 'Without assigning points to Blade Sentinel skill',
    level: 6,
    deactivatesSkills: [RULE_NO_BLADE_FURY, RULE_NO_BLADE_SHIELD],
  },
  {
    id: RULE_NO_COBRA_STRIKE,
    name: 'Without assigning points to Cobra Strike skill',
    level: 12,
    deactivatesSkills: [RULE_NO_PHOENIX_STRIKE],
  },
  {
    id: RULE_NO_WEAPON_BLOCK,
    name: 'Without assigning points to Weapon Block skill',
    level: 12,
    deactivatesSkills: [RULE_NO_SHADOW_WARRIOR, RULE_NO_SHADOW_MASTER],
  },
  {
    id: RULE_NO_CLOAK_OF_SHADOWS,
    name: 'Without assigning points to Cloak of Shadows skill',
    level: 12,
    deactivatesSkills: [RULE_NO_MIND_BLAST],
  },
  {
    id: RULE_NO_CHARGED_BOLT_SENTRY,
    name: 'Without assigning points to Charged bolt Sentry',
    level: 12,
    deactivatesSkills: [RULE_NO_LIGHTNING_SENTRY, RULE_NO_DEATH_SENTRY],
  },
  {
    id: RULE_NO_WAKE_OF_FIRE,
    name: 'Without assigning points to Wake of Fire skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_BLADE_FURY,
      RULE_NO_WAKE_OF_INFERNO,
      RULE_NO_BLADE_SHIELD,
    ],
  },
  {
    id: RULE_NO_CLAWS_OF_THUNDER,
    name: 'Without assigning points to Claws of Thunder skill',
    level: 18,
    deactivatesSkills: [RULE_NO_BLADES_OF_ICE, RULE_NO_PHOENIX_STRIKE],
  },
  {
    id: RULE_NO_DRAGON_TAIL,
    name: 'Without assigning points to Dragon Tail skill',
    level: 18,
    deactivatesSkills: [RULE_NO_DRAGON_FLIGHT],
  },
  {
    id: RULE_NO_FADE,
    name: 'Without assigning points to Fade skill',
    level: 18,
    deactivatesSkills: [RULE_NO_VENOM],
  },
  {
    id: RULE_NO_SHADOW_WARRIOR,
    name: 'Without assigning points to Shadow Warrior skill',
    level: 18,
    deactivatesSkills: [RULE_NO_SHADOW_MASTER],
  },
  {
    id: RULE_NO_BLADE_FURY,
    name: 'Without assigning points to Blade Fury skill',
    level: 18,
    deactivatesSkills: [RULE_NO_BLADE_SHIELD],
  },
  {
    id: RULE_NO_BLADES_OF_ICE,
    name: 'Without assigning points to Blades of ice skill',
    level: 24,
    deactivatesSkills: [RULE_NO_PHOENIX_STRIKE],
  },
  {
    id: RULE_NO_DRAGON_FLIGHT,
    name: 'Without assigning points to Dragon Flight skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_MIND_BLAST,
    name: 'Without assigning points to Mind Blast skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_LIGHTNING_SENTRY,
    name: 'Without assigning points to Lightning Sentry skill',
    level: 24,
    deactivatesSkills: [RULE_NO_DEATH_SENTRY],
  },
  {
    id: RULE_NO_WAKE_OF_INFERNO,
    name: 'Without assigning points to Wake of Inferno skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_PHOENIX_STRIKE,
    name: 'Without assigning points to Phoenix Strike skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_VENOM,
    name: 'Without assigning points to Venom skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SHADOW_MASTER,
    name: 'Without assigning points to Shadow Master skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_DEATH_SENTRY,
    name: 'Without assigning points to Death Sentry skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_BLADE_SHIELD,
    name: 'Without assigning points to Blade Shield skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = assasinRules;
