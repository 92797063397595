const BASE = 'app/game/';

const SET_DIFFICULTY = BASE + 'SET_DIFFICULTY';
const SET_PLAYED_CLASS = BASE + 'SET_PLAYED_CLASS';
const NEXT_PHASE = BASE + 'NEXT_PHASE';
const NEXT_LEVEL_GAP_REACHED = BASE + 'NEXT_LEVEL_GAP_REACHED';
const UPDATE_PLAYER_SKILL_RULES = BASE + 'UPDATE_PLAYER_SKILL_RULES';
const SET_AVAILABLE_LEVEL_RULE_COUNT = BASE + 'SET_AVAILABLE_LEVEL_RULE_COUNT';
const REROLL_PLAYER_LAST_LEVEL_RULE = BASE + 'REROLL_PLAYER_LAST_LEVEL_RULE';
const SET_PHASE_GOAL_STATE = BASE + 'SET_PHASE_GOAL_STATE';
const SET_ADDITIONAL_PHASE_GOAL_STATE = BASE + 'SET_ADDITIONAL_PHASE_GOAL_STATE';
const START_GAME = BASE + 'START_GAME';
const RESTART_GAME = BASE + 'RESTART_GAME';
const RESTORE_GAME = BASE + 'RESTORE_GAME';
const RESET_GAME = BASE + 'RESET_GAME';
const SET_CONTROLLED_PLAYER = BASE + 'SET_CONTROLLED_PLAYER';
const SET_DISPLAY_NAME = BASE + 'SET_DISPLAY_NAME';
const CHANGE_RULE = BASE + 'CHANGE_RULE';
const SET_PHASE_LOADING = BASE + 'SET_PHASE_LOADING';
const SET_UNLOCKED_DIFFICULTIES = BASE + 'SET_UNLOCKED_DIFFICULTIES';

export default {
  SET_DIFFICULTY,
  SET_PLAYED_CLASS,
  NEXT_PHASE,
  NEXT_LEVEL_GAP_REACHED,
  UPDATE_PLAYER_SKILL_RULES,
  SET_AVAILABLE_LEVEL_RULE_COUNT,
  REROLL_PLAYER_LAST_LEVEL_RULE,
  SET_PHASE_GOAL_STATE,
  SET_ADDITIONAL_PHASE_GOAL_STATE,
  START_GAME,
  RESTART_GAME,
  RESTORE_GAME,
  RESET_GAME,
  SET_CONTROLLED_PLAYER,
  SET_DISPLAY_NAME,
  CHANGE_RULE,
  SET_PHASE_LOADING,
  SET_UNLOCKED_DIFFICULTIES,
};
