import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { RulesActions } from '../../../redux/Rules';
import colors from '../../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faStar } from '@fortawesome/free-solid-svg-icons';

const Tooltip = styled.div`
  display: none;
  padding: 0.2em 2rem;
  font-size: 12px;
`;

const Styled = styled.button`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #302220;
  border-radius: 4px;
  background-color: #231717;
  color: #d2c8ae;
  padding: 0.2em 2rem;
  min-height: 38px;
  margin-bottom: 1em;
  font-size: 18px;
  gap: 8px;
`;

const StreamerProfileRule = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #d2c8ae;
  font-size: 12px;
  background-color: ${colors.grey[1]};
  border: 0;
  width: 100%;
  padding: 0 12px;
  gap: 4px;
`;

const RuleText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Rule = ({ rule, useStreamerLayout }) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(RulesActions.rollRule(rule));
  };

  if (useStreamerLayout) {
    return (
      <StreamerProfileRule onClick={handleOnClick}>
        {rule.multiplier !== 1 && (
          <FontAwesomeIcon icon={faStar} size="24" color={colors.primaryText} />
        )}
        {rule.extraTokens !== 0 && (
          <FontAwesomeIcon
            icon={faCoins}
            size="24"
            color={colors.primaryText}
          />
        )}
        <RuleText>{rule.name}</RuleText>
      </StreamerProfileRule>
    );
  } else {
    return (
      <Styled title={rule.description} onClick={handleOnClick}>
        {rule.multiplier !== 1 && (
          <FontAwesomeIcon icon={faStar} size="24" color={colors.primaryText} />
        )}
        {rule.extraTokens !== 0 && (
          <FontAwesomeIcon
            icon={faCoins}
            size="24"
            color={colors.primaryText}
          />
        )}
        <RuleText>{rule.name}</RuleText>
        {rule.tooltip && <Tooltip>{rule.tooltip}</Tooltip>}
      </Styled>
    );
  }
};

Rule.propTypes = {
  rule: PropTypes.objectOf(PropTypes.string),
  canRoll: PropTypes.bool,
};

export default Rule;
