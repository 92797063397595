import React from 'react';
import colors from '../../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faStar } from '@fortawesome/free-solid-svg-icons';

const { default: styled } = require('styled-components');

const RuleText = styled.div`
  color: ${colors.primaryText};
  font-size: 22px;
  padding-top: 20px;
`;

const Modifiers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Modifier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
`;

const RuleDetails = ({ rule }) => {
  return (
    <>
      <RuleText>{rule.name}</RuleText>
      <Modifiers>
        <Modifier>
          {rule.extraTokens > 0 ? '+' : ''}
          {rule.extraTokens}
          <FontAwesomeIcon
            icon={faCoins}
            size="24"
            color={colors.primaryText}
          />
        </Modifier>
        <Modifier>
          {rule.multiplier > 1 && (
            <>
              + {parseInt(rule.multiplier * 100 - 100)} %
              <FontAwesomeIcon
                icon={faStar}
                size="24"
                color={colors.primaryText}
              />
            </>
          )}
          {rule.multiplier < 1 && (
            <>
              {parseInt(rule.multiplier * 100 - 100)} %
              <FontAwesomeIcon
                icon={faStar}
                size="24"
                color={colors.primaryText}
              />
            </>
          )}
        </Modifier>
      </Modifiers>
      <SmallTitle>{rule.tooltip}</SmallTitle>
    </>
  );
};

export default RuleDetails;
