const BASE = 'app/rules/';

const ADD_RULES = BASE + 'ADD_RULES';
const UPDATE_EXCLUDED_SKILLS = BASE + 'UPDATE_EXCLUDED_SKILLS';
const UPDATE_INCLUDED_SKILLS = BASE + 'UPDATE_INCLUDED_SKILLS';
const ROLL_RULE = BASE + 'ROLL_RULE';
const SET_ROLLED_NEW_RULES = BASE + 'SET_ROLLED_NEW_RULES';
const ROLL_NEW_RULES = BASE + 'ROLL_NEW_RULES';
const SET_RULE_TO_ROLL = BASE + 'SET_RULE_TO_ROLL';
const SET_SELECTED_RULE = BASE + 'SET_SELECTED_RULE';
const CHOOSE_SELECTED_RULE = BASE + 'CHOOSE_SELECTED_RULE';
const RESTART_GAME = BASE + 'RESTART_GAME';
const RESTORE_RULES = BASE + 'RESTORE_RULES';

export default {
  ADD_RULES,
  UPDATE_EXCLUDED_SKILLS,
  UPDATE_INCLUDED_SKILLS,
  ROLL_RULE,
  ROLL_NEW_RULES,
  SET_ROLLED_NEW_RULES,
  SET_RULE_TO_ROLL,
  SET_SELECTED_RULE,
  CHOOSE_SELECTED_RULE,
  RESTART_GAME,
  RESTORE_RULES,
};
