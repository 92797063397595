const CLASS_SORCERESS = 'CLASS_SORCERESS';
const CLASS_NECROMANCER = 'CLASS_NECROMANCER';
const CLASS_DRUID = 'CLASS_DRUID';
const CLASS_ASSASIN = 'CLASS_ASSASIN';
const CLASS_AMAZON = 'CLASS_AMAZON';
const CLASS_BARBARIAN = 'CLASS_BARBARIAN';
const CLASS_PALADIN = 'CLASS_PALADIN';

const classesArray = [
  CLASS_AMAZON,
  CLASS_ASSASIN,
  CLASS_NECROMANCER,
  CLASS_DRUID,
  CLASS_SORCERESS,
  CLASS_BARBARIAN,
  CLASS_PALADIN,
];

const classesTypes = {
  sorceress: CLASS_SORCERESS,
  necromancer: CLASS_NECROMANCER,
  druid: CLASS_DRUID,
  assasin: CLASS_ASSASIN,
  amazon: CLASS_AMAZON,
  barbarian: CLASS_BARBARIAN,
  paladin: CLASS_PALADIN,
};

const classes = {
  [classesTypes.barbarian]: {
    displayName: 'Barbarian',
  },
  [classesTypes.sorceress]: {
    displayName: 'Sorceress',
  },
  [classesTypes.amazon]: {
    displayName: 'Amazon',
  },
  [classesTypes.necromancer]: {
    displayName: 'Necromancer',
  },
  [classesTypes.druid]: {
    displayName: 'Druid',
  },
  [classesTypes.paladin]: {
    displayName: 'Paladin',
  },
  [classesTypes.assasin]: {
    displayName: 'Assasin',
  },
};

module.exports = { classesArray, classesTypes, classes };
