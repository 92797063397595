import Title from '../../baseComponents/Title';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { useSelector } from 'react-redux';

const StreamerProfileRule = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #d2c8ae;
  font-size: 12px;
  background-color: ${colors.grey[1]};
  border: 0;
  width: 100%;
  padding: 0 12px;
`;

const RuleText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SkillRules = () => {
  const {
    game: { players, controlledPlayer },
    settings: { showLevelPhaseModal },
  } = useSelector((state) => state);

  const ShowedSkills = players[controlledPlayer].skillRules?.map(
    (skillRule) => {
      if (skillRule) {
        if (
          skillRule.level === players[controlledPlayer].lastLevelGapReached &&
          showLevelPhaseModal
        ) {
          return null;
        }

        return (
          <StreamerProfileRule key={skillRule?.name}>
            <RuleText>{skillRule.name}</RuleText>
          </StreamerProfileRule>
        );
      }
      return null;
    },
  );

  return (
    <div>
      <Title text="Skill rules" />
      {ShowedSkills}
    </div>
  );
};

export default SkillRules;
