import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BaseSelector = styled.select`
  border-radius: 4px;
  background-color: #444444;
  color: #ffffff;
  min-width: 10rem;
  text-align: center;
  text-align-last: center;
  padding: 0.25em;
  padding-left: 15px;
  font-size: 16px;
`;

const Container = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0px;
  text-align: center;
  font-size: 14px;
  color: #d2c8ae;
`;

const baseSelector = ({ label, options, onChange, id, value, disabled }) => {
  const Options = options.map((option) => {
    return (
      <option value={option.value} key={option.value}>
        {option.name}
      </option>
    );
  });

  return (
    <Container>
      <Label htmlFor={id}>{label}</Label>
      <BaseSelector
        id={id}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {Options}
      </BaseSelector>
    </Container>
  );
};

baseSelector.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default baseSelector;
