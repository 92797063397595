import { combineReducers } from 'redux';
import SettingsTypes from './Settings';
import UserTypes from './User';
import GameTypes from './Game';
import RulesTypes from './Rules';
import SessionsTypes from './Sessions';

const rootReducer = combineReducers({
  settings: SettingsTypes,
  user: UserTypes,
  game: GameTypes,
  rules: RulesTypes,
  sessions: SessionsTypes,
});

export default rootReducer;
