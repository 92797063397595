import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGa from 'react-ga';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import Landing from './components/landing';
import NewPlay from './components/roguelite/index';
import '../node_modules/react-toastify/dist/ReactToastify.min.css';
import { release } from './release';

const App = () => {
  const history = createBrowserHistory();
  ReactGa.initialize('UA-176997892-1');
  history.listen((location) => {
    ReactGa.set({ page: location.pathname });
    ReactGa.pageview(location.pathname);
  });

  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [history]);
  return (
    <main>
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/" element={<Landing />} exact />
          <Route path="/play" element={<NewPlay />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} />
    </main>
  );
};

export default App;
