const player = {
  displayName: '',
  playedClass: 'RANDOM',
  rules: [],
  skillRules: [],
  lastLevelGapReached: 0,
  availableLevelRuleCount: 0,
};

export default {
  currentPhase: 0,
  controlledPlayer: 0,
  rulesRerollCount: 0,
  phaseGoals: [],
  players: [player, player, player, player, player, player, player, player],
  phaseLoading: false,
  tokens: 0,
  score: 0,
};
