import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getPlayedClass } from '../../redux/Game/selectors';
import BaseContainer from '../baseContainer';
import NavigationBar from '../navigationBar';
import RulesContainer from './components/rulesContainer';
import PhaseContainer from './components/phaseContainer';
import CharacterSelector from './components/characterSelector';
import StartButton from './components/startButton';
import PhaseModal from './components/PhaseModal';
import RuleRollModal from './components/RuleRollModal';
import LoginMenu from '../loginMenu';
import RestartGameModal from '../roguelite/components/RestartGameModal';
import BaseInput from '../baseInput';
import colors from '../../utils/colors';
import PlayerCountSelector from './components/PlayerCountSelector';
import { UserActions } from '../../redux/User';
import SkillsContainer from './components/skillsContainer';
import LevelGapModal from './components/LevelGapModal';
import StreamerLayout from './components/StreamerLayout';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
`;

const StartButtonContainer = styled.div`
  margin: 140px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 234px);
  color: ${colors.primaryText};
`;

const NewPlay = () => {
  const dispatch = useDispatch();
  const playedClass = useSelector((state) => getPlayedClass(state));
  const {
    game: { currentPhase, players },
    settings: {
      showPhaseModal,
      showRuleRollModal,
      numberOfPlayers,
      showLevelPhaseModal,
      showRestartGameModal,
      showAdvancedSettings,
      useStreamerLayout,
    },
    user: { showLoginModal, sessionToken, sessionName },
  } = useSelector((state) => state);

  const handleSessionNameChange = (data) => {
    dispatch(UserActions.setSessionName(data.target.value));
  };

  const playersArray = [];
  for (let p = 0; p < numberOfPlayers; p += 1) {
    playersArray.push(
      <CharacterSelector
        player={p}
        value={players[p].displayName}
        classValue={players[p].playedClass}
      />,
    );
  }

  return (
    <>
      {showPhaseModal && <PhaseModal />}
      {showLevelPhaseModal && <LevelGapModal />}
      {showRuleRollModal && <RuleRollModal />}
      {showLoginModal && <LoginMenu />}
      {showRestartGameModal && <RestartGameModal />}

      <RootContainer>
        <NavigationBar />
        <BaseContainer
          backgroungColor="#121a20"
          imageUrl={currentPhase > 0 ? playedClass?.imageUrl ?? '' : null}
          flexDirection="row"
          fullHeight
        >
          {currentPhase > 0 ? (
            <>
              {useStreamerLayout ? (
                <StreamerLayout />
              ) : (
                <>
                  <RulesContainer />
                  <PhaseContainer />
                  <SkillsContainer />
                </>
              )}
            </>
          ) : (
            <StartButtonContainer>
              <h4>Party setup</h4>
              {!sessionToken && (
                <PlayerContainer>
                  <BaseInput
                    label="Name your party or playthrough"
                    onChange={handleSessionNameChange}
                    width="300px"
                    nomargin
                    value={sessionName}
                  />
                </PlayerContainer>
              )}
              <SettingsContainer>
                <PlayerCountSelector />
              </SettingsContainer>
              <PlayerContainer>{playersArray}</PlayerContainer>
              <StartButton />
              <p></p>
            </StartButtonContainer>
          )}
        </BaseContainer>
      </RootContainer>
    </>
  );
};

export default NewPlay;
