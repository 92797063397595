import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Title from './Title';

const Container = styled.div`
  align-content: center;
  justify-content: center;
`;

const Info = styled.p`
  text-align: center;
  color: #d2c8ae;
  font-size: ${(props) => (props.size === 'L' ? '48px' : '24px')};
`;

const InfoField = ({ title, info, size }) => {
  return (
    <Container>
      <Title text={title} size="S" />
      <Info size={size}>{info}</Info>
    </Container>
  );
};

InfoField.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  size: PropTypes.string,
};

export default InfoField;
