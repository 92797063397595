import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Title from './baseComponents/Title';
import BaseButton from './baseButton';
import BaseInput from './baseInput';
import { UserActions } from '../redux/User';
import colors from '../utils/colors';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding: 16px;
  padding-bottom: 24px;
  width: 60%;
  max-width: 650px;
  border: 2px solid ${colors.grey[3]};
  border-radius: 8px;
  background-color: ${colors.grey[1]};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  margin-top: 30px;
`;

const Space = styled.p`
  height: 50px;
`;

const Para = styled.p``;

const LoginMenu = () => {
  const dispatch = useDispatch();
  const {
    user: { loadSessionInput, sessionName, sessionToken },
    settings: { numberOfPlayers },
  } = useSelector((state) => state);

  const [showSessionKey, setShowSessionKey] = useState(false);

  return (
    <Container>
      <Modal>
        <Title text="New session started" />
        {sessionName === '' ? (
          <React.Fragment key="withoutLogin">
            <SmallTitle>Load previous session</SmallTitle>
            <BaseInput
              key="LoadSessionInput"
              label="Session secret"
              value={loadSessionInput}
              onChange={(e) =>
                dispatch(UserActions.setLoadSessionInput(e.target.value))
              }
            />
            <BaseButton
              key="loadSessionButton"
              size="18"
              text="Load Session"
              onClick={() => dispatch(UserActions.loadSession())}
            />
          </React.Fragment>
        ) : (
          <React.Fragment key="LoggedIn">
            <SmallTitle>
              {numberOfPlayers > 1
                ? 'Share this session token with other players'
                : 'In session with secret:'}
            </SmallTitle>
            <BaseButton
              onClick={() => setShowSessionKey(!showSessionKey)}
              text={showSessionKey ? 'hide session key' : 'show session key'}
            />
            {showSessionKey && <Title text={sessionToken} />}
            {!showSessionKey && <Title text="****************" />}
            <Para>Keep it secret, keep it safe.</Para>
            <Para>
              If you want to continue this same session later. It can be good
              idea to store this session key somewhere.
            </Para>
          </React.Fragment>
        )}
        <Space />
        <BaseButton
          size="18"
          text="Close"
          onClick={() => dispatch(UserActions.setShowLoginModal(false))}
        />
      </Modal>
    </Container>
  );
};

export default LoginMenu;
