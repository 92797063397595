const { ruleClasses } = require('../types/rules');

const RULE = 'RULE_';
const NO_GEMS = RULE + 'NO_GEMS';
const NO_JEWELS = RULE + 'NO_JEWELS';
const NO_RUNES = RULE + 'NO_RUNES';
const NO_SMALL_CHARMS = RULE + 'NO_SMALL_CHARMS';
const NO_LARGE_CHARMS = RULE + 'NO_LARGE_CHARMS';
const NO_GRAND_CHARMS = RULE + 'NO_GRAND_CHARMS';
const NO_HEALING_POTIONS = RULE + 'NO_HEALING_POTIONS';
const NO_MANA_POTIONS = RULE + 'NO_MANA_POTIONS';
const NO_REJUVENATION_POTIONS = RULE + 'NO_REJUVENATION_POTIONS';
const NO_OTHER_POTIONS = RULE + 'NO_OTHER_POTIONS';

const usablesRules = [
  {
    id: NO_GEMS,
    name: 'Without attaching Gems to gear',
    type: ruleClasses.usablesRule,
    tooltip: 'You can use gems in other purposes like crafting, if not restricted by another rule.',
    multiplier: 1.04,
    extraTokens: 0,
  },
  {
    id: NO_JEWELS,
    name: 'Without attaching Jewels to gear',
    type: ruleClasses.usablesRule,
    tooltip: 'You can use jewels in other purposes like crafting, if not restricted by another rule.',
    multiplier: 1,
    extraTokens: 0,
  },
  {
    id: NO_RUNES,
    name: 'Without attaching plain Runes to gear',
    type: ruleClasses.usablesRule,
    tooltip: 'You can still make runewords with the runes. I know, weird. You can also use runes in crafting etc. if not resticted by another rule.',
    multiplier: 1.06,
    extraTokens: 1,
  },
  {
    id: NO_SMALL_CHARMS,
    name: 'Without using Small Charms',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer keep small charms in your inventory. Stash em and use later if you want to try to reroll this rule for something else. Or give them to friend if playing in group.',
    multiplier: 1.16,
    extraTokens: 3,
  },
  {
    id: NO_LARGE_CHARMS,
    name: 'Without using Large Charms',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer keep large charms in your inventory. Stash em and use later if you want to reroll this rule later. Or give them to friend if playing in group.',
    multiplier: 1.14,
    extraTokens: 2,
  },
  {
    id: NO_GRAND_CHARMS,
    name: 'Without using Grand Charms',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer keep grand charms in your inventory. Stash em and use later if you want to reroll this rule later. Or give them to friend if playing in group.',
    multiplier: 1.15,
    extraTokens: 3,
  },
  {
    id: NO_HEALING_POTIONS,
    name: 'Without using Healing Potions',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer use any Healing potions on you.',
    multiplier: 1.2,
    extraTokens: 3,
  },
  {
    id: NO_MANA_POTIONS,
    name: 'Without using Mana Potions',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer use any Mana potions on you.',
    multiplier: 1.2,
    extraTokens: 3,
  },
  {
    id: NO_REJUVENATION_POTIONS,
    name: 'Without using Rejuvenation Potions',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer use any Rejuvenation potions on you.',
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_OTHER_POTIONS,
    name: 'Without using stamina, thawing, antidote or any throwing Potions',
    type: ruleClasses.usablesRule,
    tooltip: 'You can no longer use any Stamina, Thawing, Antidote or any of the throwing type potions.',
    multiplier: 0.85,
    extraTokens: -3,
  },
];

module.exports = usablesRules;
