const { ruleClasses } = require('../types/rules');

const RULE = 'RULE_';
const NO_TOWN_PORTALS = RULE + 'NO_TOWN_PORTALS';
const NO_REPAIRING = RULE + 'NO_REPAIRING';
const NO_CUBE = RULE + 'NO_CUBE';
const NO_SHRINES = RULE + 'NO_SHRINES';
const NO_MERCENARIES = RULE + 'NO_MERCENARIES';
const NO_CHESTS = RULE + 'NO_CHESTS';
const NO_TRADING = RULE + 'NO_TRADING';
const NO_SELLING = RULE + 'NO_SELLING';
const NO_SIDE_QUEST_REWARDS = RULE + 'NO_SIDE_QUEST_REWARDS';
const NO_MAP = RULE + 'NO_MAP';
const NO_RESPEC = RULE + 'NO_RESPEC';
const NO_TOMES = RULE + 'NO_TOMES';

const generalRules = [
  {
    id: NO_TOWN_PORTALS,
    name: 'Without using Town Portals',
    type: ruleClasses.generalRule,
    tooltip: 'You cannot use townportals. Even if one of your party members use it. You must find another way to town.',
    multiplier: 1.12,
    extraTokens: 2,
  },
  {
    id: NO_REPAIRING,
    name: 'Without repairing items',
    type: ruleClasses.generalRule,
    tooltip: 'All your items are considered to be ethereal. Once items break, you cannot no longer use them.',
    multiplier: 0.95,
    extraTokens: -1,
  },
  {
    id: NO_CUBE,
    name: 'Without using Horadric Cube (Other than for quests)',
    type: ruleClasses.generalRule,
    tooltip: 'That basically means no crafting or combining gems and runes. You still can ofcourse use the mandatory cube recipes that is required to complete the quests.',
    multiplier: 0.9,
    extraTokens: -2,
  },
  {
    id: NO_SHRINES,
    name: 'Without Shrines or Health or Mana pools etc.',
    type: ruleClasses.generalRule,
    multiplier: 0.9,
    extraTokens: -2,
  },
  {
    id: NO_MERCENARIES,
    name: 'Without resurrecting Mercenaries (you can still hire them)',
    type: ruleClasses.generalRule,
    tooltip: 'If your mercenary dies you need to hire new one instead of resurrecting the existing. Consider it like HC mercenaries.',
    multiplier: 1.04,
    extraTokens: 0,
  },
  {
    id: NO_TRADING,
    name: 'Without purchasing or gambling from merchants',
    type: ruleClasses.generalRule,
    tooltip: 'You cannot buy or gamble gear from merchants. You still can sell your stuff if not restricted by another rule.',
    multiplier: 0.97,
    extraTokens: 0,
  },
  {
    id: NO_SELLING,
    name: 'Without selling any items or gear to merchants',
    type: ruleClasses.generalRule,
    tooltip: 'You no longer can sell your loot to merchants. You still can buy stuff from them if you have the money and thats not restricted by another rule.',
    multiplier: 1.03,
    extraTokens: 0,
  },
  {
    id: NO_SIDE_QUEST_REWARDS,
    name: 'Without taking side quest rewards',
    type: ruleClasses.generalRule,
    tooltip: 'Even if you or some of your group completes a side quest. You are no longer allowed to take the reward. If the reward is automatically rewarded you can leave the game before rest of the group completes the quest.',
    multiplier: 1,
    extraTokens: 0,
  },
  {
    id: NO_MAP,
    name: 'Without using map',
    type: ruleClasses.generalRule,
    tooltip: 'You are no longer allowed to use map or minimap. You should remap the keybinding to something you are not using for anything else to prevent you from accidently opening the map.',
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_RESPEC,
    name: 'No respec',
    type: ruleClasses.generalRule,
    tooltip: 'No more respecs. Choose your skills wisely from this point on..',
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_TOMES,
    name: 'Without using tomes',
    type: ruleClasses.generalRule,
    tooltip: 'Tomes means Tome of Townportals and identification. You can still use the individual scrolls',
    multiplier: 1,
    extraTokens: 0,
  },
];

module.exports = generalRules;
