const RULE_NO_PRAYER = 'RULE_NO_PRAYER';
const RULE_NO_RESIST_FIRE = 'RULE_NO_RESIST_FIRE';
const RULE_NO_MIGHT = 'RULE_NO_MIGHT';
const RULE_NO_SACRIFICE = 'RULE_NO_SACRIFICE';
const RULE_NO_SMITE = 'RULE_NO_SMITE';

const RULE_NO_DEFIANCE = 'RULE_NO_DEFIANCE';
const RULE_NO_RESIST_COLD = 'RULE_NO_RESIST_COLD';
const RULE_NO_HOLY_FIRE = 'RULE_NO_HOLY_FIRE';
const RULE_NO_THORNS = 'RULE_NO_THORNS';
const RULE_NO_HOLY_BOLT = 'RULE_NO_HOLY_BOLT';

const RULE_NO_CLEANSING = 'RULE_NO_CLEANSING';
const RULE_NO_RESIST_LIGHTNING = 'RULE_NO_RESIST_LIGHTNING';
const RULE_NO_BLESSED_AIM = 'RULE_NO_BLESSED_AIM';
const RULE_NO_ZEAL = 'RULE_NO_ZEAL';
const RULE_NO_CHARGE = 'RULE_NO_CHARGE';

const RULE_NO_VIGOR = 'RULE_NO_VIGOR';
const RULE_NO_CONCENTRATION = 'RULE_NO_CONCENTRATION';
const RULE_NO_HOLY_FREEZE = 'RULE_NO_HOLY_FREEZE';
const RULE_NO_VENGEANCE = 'RULE_NO_VENGEANCE';
const RULE_NO_BLESSED_HAMMERS = 'RULE_BLESSED_HAMMERS';

const RULE_NO_MEDITATION = 'RULE_NO_MEDITATION';
const RULE_NO_HOLY_SHOCK = 'RULE_NO_HOLY_SHOCK';
const RULE_NO_SANCTUARY = 'RULE_NO_SANCTUARY';
const RULE_NO_CONVERSION = 'RULE_NO_CONVERSION';
const RULE_NO_HOLY_SHIELD = 'RULE_HOLY_SHIELD';

const RULE_NO_REDEMPTION = 'RULE_NO_REDEMPTION';
const RULE_NO_SALVATION = 'RULE_NO_SALVATION';
const RULE_NO_FANATICISM = 'RULE_NO_FANATICISM';
const RULE_NO_CONVICTION = 'RULE_NO_CONVICTION';
const RULE_NO_FIST_OF_HEAVENS = 'RULE_NO_FIST_OF_HEAVENS';

const paladinRules = [
  {
    id: RULE_NO_PRAYER,
    name: 'Without assigning points to Prayer skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_CLEANSING,
      RULE_NO_VIGOR,
      RULE_NO_MEDITATION,
      RULE_NO_REDEMPTION,
    ],
  },
  {
    id: RULE_NO_RESIST_FIRE,
    name: 'Without assigning points to Resist Fire skill',
    level: 1,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_MIGHT,
    name: 'Without assigning points to Might skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_HOLY_FIRE,
      RULE_NO_BLESSED_AIM,
      RULE_NO_CONCENTRATION,
      RULE_NO_HOLY_FREEZE,
      RULE_NO_HOLY_SHOCK,
      RULE_NO_FANATICISM,
      RULE_NO_SANCTUARY,
      RULE_NO_CONVICTION,
    ],
  },
  {
    id: RULE_NO_SACRIFICE,
    name: 'Without assigning points to Sacrifice skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_ZEAL,
      RULE_NO_VENGEANCE,
      RULE_NO_CONVERSION,
      RULE_NO_FIST_OF_HEAVENS,
    ],
  },
  {
    id: RULE_NO_SMITE,
    name: 'Without assigning points to Smite skill',
    level: 1,
    deactivatesSkills: [RULE_NO_CHARGE, RULE_NO_HOLY_SHIELD],
  },
  {
    id: RULE_NO_DEFIANCE,
    name: 'Without assigning points to Defiance skill',
    level: 6,
    deactivatesSkills: [RULE_NO_VIGOR, RULE_NO_REDEMPTION],
  },
  {
    id: RULE_NO_RESIST_COLD,
    name: 'Without assigning points to Resist Cold skill',
    level: 6,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_HOLY_FIRE,
    name: 'Without assigning points to Holy Fire skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_HOLY_FREEZE,
      RULE_NO_HOLY_SHOCK,
      RULE_NO_SANCTUARY,
      RULE_NO_CONVICTION,
    ],
  },
  {
    id: RULE_NO_THORNS,
    name: 'Without assigning points to Thorns skill',
    level: 6,
    deactivatesSkills: [RULE_NO_SANCTUARY, RULE_NO_CONVICTION],
  },
  {
    id: RULE_NO_HOLY_BOLT,
    name: 'Without assigning points to Holy Bolt skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_BLESSED_HAMMERS,
      RULE_NO_FIST_OF_HEAVENS,
      RULE_NO_HOLY_SHIELD,
    ],
  },
  {
    id: RULE_NO_CLEANSING,
    name: 'Without assigning points to Cleansing skill',
    level: 12,
    deactivatesSkills: [RULE_NO_VIGOR, RULE_NO_MEDITATION, RULE_NO_REDEMPTION],
  },
  {
    id: RULE_NO_RESIST_LIGHTNING,
    name: 'Without assigning points to Resist Lightning skill',
    level: 12,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_BLESSED_AIM,
    name: 'Without assigning points to Blessed Aim skill',
    level: 12,
    deactivatesSkills: [RULE_NO_CONCENTRATION, RULE_NO_FANATICISM],
  },
  {
    id: RULE_NO_ZEAL,
    name: 'Without assigning points to Zeal skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_VENGEANCE,
      RULE_NO_CONVERSION,
      RULE_NO_FIST_OF_HEAVENS,
    ],
  },
  {
    id: RULE_NO_CHARGE,
    name: 'Without assigning points to Charge skill',
    level: 12,
    deactivatesSkills: [RULE_NO_HOLY_SHIELD],
  },
  {
    id: RULE_NO_VIGOR,
    name: 'Without assigning points to Vigor skill',
    level: 18,
    deactivatesSkills: [RULE_NO_REDEMPTION],
  },
  {
    id: RULE_NO_CONCENTRATION,
    name: 'Without assigning points to Concentration skill',
    level: 18,
    deactivatesSkills: [RULE_NO_FANATICISM],
  },
  {
    id: RULE_NO_HOLY_FREEZE,
    name: 'Without assigning points to Holy Freeze skill',
    level: 18,
    deactivatesSkills: [
      RULE_NO_HOLY_SHOCK,
      RULE_NO_SANCTUARY,
      RULE_NO_CONVICTION,
    ],
  },
  {
    id: RULE_NO_VENGEANCE,
    name: 'Without assigning points to Vengeance skill',
    level: 18,
    deactivatesSkills: [RULE_NO_CONVERSION, RULE_NO_FIST_OF_HEAVENS],
  },
  {
    id: RULE_NO_BLESSED_HAMMERS,
    name: 'Without assigning points to Blessed Hammers skill',
    level: 18,
    deactivatesSkills: [RULE_NO_FIST_OF_HEAVENS, RULE_NO_HOLY_SHIELD],
  },
  {
    id: RULE_NO_MEDITATION,
    name: 'Without assigning points to Meditation skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_HOLY_SHOCK,
    name: 'Without assigning points to Holy Shock skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SANCTUARY,
    name: 'Without assigning points to Sanctuary skill',
    level: 24,
    deactivatesSkills: [RULE_NO_CONVICTION],
  },
  {
    id: RULE_NO_CONVERSION,
    name: 'Without assigning points to Conversion skill',
    level: 24,
    deactivatesSkills: [RULE_NO_FIST_OF_HEAVENS],
  },
  {
    id: RULE_NO_HOLY_SHIELD,
    name: 'Without assigning points to Holy Shield skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_REDEMPTION,
    name: 'Without assigning points to Redemption skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SALVATION,
    name: 'Without assigning points to Salvation skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FANATICISM,
    name: 'Without assigning points to Fanaticism skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_CONVICTION,
    name: 'Without assigning points to Conviction skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FIST_OF_HEAVENS,
    name: 'Without assigning points to Fist of the Heavens skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = paladinRules;
