import produce from 'immer';
import types from './types';
import initialState from './initialState';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_CONTROLLED_PLAYER: {
      draft.controlledPlayer = data;

      break;
    }

    case types.SET_DIFFICULTY: {
      draft.difficulty = data;

      break;
    }

    case types.SET_PLAYED_CLASS: {
      draft.players[data.player].playedClass = data.playedClass;

      break;
    }

    case types.SET_PHASE_GOAL_STATE: {
      const index = draft.phaseGoals.findIndex((goal) => goal.id === data.id);
      if (index > -1) {
        draft.phaseGoals[index].done = !draft.phaseGoals[index].done;
      }

      break;
    }

    case types.SET_ADDITIONAL_PHASE_GOAL_STATE: {
      const index = draft.phaseGoals.findIndex((goal) => goal.id === data.goal.id);
      if (index > -1) {
        draft.phaseGoals[index].additionalGoals[data.childIndex].done = !draft.phaseGoals[index].additionalGoals[data.childIndex].done
      }

      break;
    }

    case types.RESTART_GAME: {
      const players = draft.players.map((p) => {
        return {
          displayName: p.displayName,
          playedClass: p.playedClass,
          rules: [],
          phaseGoals: [],
          excludedSkills: [],
          includedSkills: [],
          excludedPassiveSkills: [],
          includedPassiveSkills: [],
          rulesRerollCount: 0,
        };
      });

      draft.players = players;
      draft.currentPhase = 0;
      draft.currentGameDifficulty = initialState.currentGameDifficulty;
      draft.tokens = 0;
      draft.score = 0;

      break;
    }

    case types.RESET_GAME: {
      return initialState;
    }

    case types.RESTORE_GAME: {
      draft.currentPhase = data.currentPhase;
      draft.currentGameDifficulty = data.currentGameDifficulty;
      draft.players = data.players;
      draft.tokens = data.tokens;
      draft.score = data.score;
      draft.phaseGoals = data.phaseGoals;
      draft.rulesRerollCount = data.rulesRerollCount;
      draft.unlockedDifficulties = data.unlockedDifficulties;

      break;
    }

    case types.UPDATE_PLAYER_SKILL_RULES: {
      draft.players[draft.controlledPlayer].skillRules = data;

      break;
    }

    case types.SET_AVAILABLE_LEVEL_RULE_COUNT: {
      draft.players[draft.controlledPlayer].availableLevelRuleCount = data;

      break;
    }

    case types.SET_DISPLAY_NAME: {
      draft.players[data.player].displayName = data.displayName;

      break;
    }

    case types.CHANGE_RULE: {
      const index = draft.players[data.ruleToRoll.player].rules.findIndex(
        (rule) => rule.id === data.ruleToRoll.rule.id,
      );
      draft.players[data.ruleToRoll.player].rules[index] = data.selectedRule;

      break;
    }

    case types.SET_PHASE_LOADING: {
      draft.phaseLoading = data;

      break;
    }

    case types.SET_UNLOCKED_DIFFICULTIES: {
      draft.unlockedDifficulties = data;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
