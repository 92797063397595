import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  font-size: 18px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #302220;
  border-radius: 4px;
  background-color: #231717;
  color: #d2c8ae;
  padding: 0.2em 1rem;
  margin-bottom: 1em;
`;

const Skill = styled.div`
  display: flex;
  text-align: center;
`;

const ExcludedSkill = ({ skill }) => {
  return (
    <Container>
      <Skill alt={skill.name}>{skill.name}</Skill>
    </Container>
  );
};

ExcludedSkill.propTypes = {
  skill: PropTypes.objectOf(PropTypes.string),
};

export default ExcludedSkill;
