import React from 'react';
import { useSelector } from 'react-redux';
import { getPlayedClass } from '../../../redux/Game/selectors';
import BaseContainer from '../../baseContainer';
import InfoField from '../../baseComponents/InfoField';
import PhaseGoals from './PhaseGoals';
import NextPhaseButton from './NextPhaseButton';

const PhaseContainer = () => {
  const {
    game: { currentPhase },
  } = useSelector((state) => state);
  const playedClass = useSelector((state) => getPlayedClass(state));

  return (
    <BaseContainer flexDirection="column">
      <InfoField title="Playing on" info={playedClass?.displayName} />
      <InfoField title="Phase" info={currentPhase} size="L" />
      <PhaseGoals />
      <NextPhaseButton />
    </BaseContainer>
  );
};

export default PhaseContainer;
