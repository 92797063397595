const BASE = 'app/settings/';

const SET_NUMBER_OF_PLAYERS = BASE + 'SET_NUMBER_OF_PLAYERS';
const SET_DIFFICULTY = BASE + 'SET_DIFFICULTY';
const SET_SHOW_INCLUDED_SKILLS = BASE + 'SET_SHOW_INCLUDED_SKILLS';
const SET_SHOW_ACTIVE_SKILLS = BASE + 'SET_SHOW_ACTIVE_SKILLS';
const SET_SHOW_PASSIVE_SKILLS = BASE + 'SET_SHOW_PASSIVE_SKILLS';
const SET_SHOW_PHASE_MODAL = BASE + 'SET_SHOT_PHASE_MODAL';
const SET_SHOW_LEVEL_PHASE_MODAL = BASE + 'SET_SHOW_LEVEL_PHASE_MODAL';
const SET_SHOW_RULE_ROLL_MODAL = BASE + 'SET_SHOW_RULE_ROLL_MODAL';
const RESTART_GAME = BASE + 'RESTART_GAME';
const RESTORE_SETTINGS = BASE + 'RESTORE_SETTINGS';
const SHOW_SESSION_TOKEN = BASE + 'SHOW_SESSION_TOKEN';
const SHOW_SESSION_MENU = BASE + 'SHOW_SESSION_MENU';
const SHOW_RESTART_GAME_MODAL = BASE + 'SHOW_RESTART_GAME_MODAL';
const SET_SAVE_TO_HALL_OF_FAME = BASE + 'SET_SAVE_TO_HALL_OF_FAME';
const SET_HALL_TO_FAME_COMMENT = BASE + 'SET_HALL_TO_FAME_COMMENT';
const SET_SHOW_ADVANCED_SETTINGS = BASE + 'SET_SHOW_ADVANCED_SETTINGS';
const SET_USE_STREAMER_LAYOUT = BASE + 'SET_USE_STREAMER_LAYOUT';
const SET_GROUP_RULES = BASE + 'SET_GROUP_RULES';

export default {
  SET_NUMBER_OF_PLAYERS,
  SET_DIFFICULTY,
  SET_SHOW_INCLUDED_SKILLS,
  SET_SHOW_ACTIVE_SKILLS,
  SET_SHOW_PASSIVE_SKILLS,
  SET_SHOW_PHASE_MODAL,
  SET_SHOW_LEVEL_PHASE_MODAL,
  SET_SHOW_RULE_ROLL_MODAL,
  RESTART_GAME,
  RESTORE_SETTINGS,
  SHOW_SESSION_TOKEN,
  SHOW_SESSION_MENU,
  SHOW_RESTART_GAME_MODAL,
  SET_SAVE_TO_HALL_OF_FAME,
  SET_HALL_TO_FAME_COMMENT,
  SET_SHOW_ADVANCED_SETTINGS,
  SET_USE_STREAMER_LAYOUT,
  SET_GROUP_RULES,
};
