const BASE = 'app/user/';

const SET_NUMBER_OF_PLAYERS = BASE + 'SET_NUMBER_OF_PLAYERS';
const CREATE_SESSION = BASE + 'CREATE_SESSION';
const LOAD_SESSION = BASE + 'LOAD_SESSION';
const SET_SESSION_TOKEN = BASE + 'SET_SESSION_TOKEN';
const SET_SESSION_NAME = BASE + 'SET_SESSION_NAME';
const SAVE_SESSION = BASE + 'SAVE_SESSION';
const EXIT_SESSION = BASE + 'EXIT_SESSION';
const CLEAR_SESSION = BASE + 'CLEAR_SESSION';
const SET_SHOW_LOGIN_MODAL = BASE + 'SET_SHOW_LOGIN_MODAL';
const SET_NEW_SESSION_NAME = BASE + 'SET_NEW_SESSION_NAME';
const SET_LOAD_SESSION_INPUT = BASE + 'SET_LOAD_SESSION_INPUT';
const RESTART_GAME = BASE + 'RESTART_GAME';
const RESTORE_USER = BASE + 'RESTORE_USER';
const ADD_SESSION_TO_LIST = BASE + 'ADD_SESSION_TO_LIST';

export default {
  SET_NUMBER_OF_PLAYERS,
  CREATE_SESSION,
  LOAD_SESSION,
  SET_SESSION_TOKEN,
  SET_SESSION_NAME,
  SAVE_SESSION,
  EXIT_SESSION,
  CLEAR_SESSION,
  SET_SHOW_LOGIN_MODAL,
  SET_NEW_SESSION_NAME,
  SET_LOAD_SESSION_INPUT,
  RESTART_GAME,
  RESTORE_USER,
  ADD_SESSION_TO_LIST,
};
