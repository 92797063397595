/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Skill from './Skill';
import BaseContainer from '../../baseContainer';
import Title from '../../baseComponents/Title';
import BaseButton from '../../baseButton';
import { GameActions } from '../../../redux/Game';

const SkillList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SkillsContainer = () => {
  const dispatch = useDispatch();
  const {
    game: { players, controlledPlayer },
    settings: { showLevelPhaseModal },
  } = useSelector((state) => state);

  const ShowedSkills = players[controlledPlayer].skillRules?.map(
    (skillRule) => {
      if (skillRule) {
        if (
          skillRule.level === players[controlledPlayer].lastLevelGapReached &&
          showLevelPhaseModal
        ) {
          return null;
        }

        return <Skill key={skillRule?.name} skill={skillRule} />;
      }
      return null;
    },
  );
  const lvlGapReached = players[controlledPlayer].lastLevelGapReached;
  const nextLevelGap = lvlGapReached === 1 ? 6 : lvlGapReached + 6;
  return (
    <BaseContainer flexDirection="column">
      <Title text="Skill Rules" />
      <SkillList>{ShowedSkills}</SkillList>
      {players[controlledPlayer].lastLevelGapReached === 0 ? (
        <BaseButton
          size="18"
          text="Roll first Skill Rule"
          onClick={() => dispatch(GameActions.nextLevelGapReached())}
        />
      ) : nextLevelGap <= 30 ? (
        <BaseButton
          size="18"
          text={'Level ' + nextLevelGap + ' reached'}
          onClick={() => dispatch(GameActions.nextLevelGapReached())}
        />
      ) : null}
    </BaseContainer>
  );
};

export default SkillsContainer;
