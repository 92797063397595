const { ruleClasses } = require('../types/rules');
const { classesTypes } = require('../types/classes');
const barbarianRules = require('./barbarianRules');
const sorceressRules = require('./sorceressRules');
const amazonRules = require('./amazonRules');
const assasinRules = require('./assasinRules');
const gearRules = require('./gearRules');
const generalRules = require('./generalRules');
const gearStatRules = require('./gearStatRules');
const usablesRules = require('./usablesRules');
const druidRules = require('./druidRules');
const paladinRules = require('./paladinRules');
const necromancerRules = require('./necromancerRules');

const rules = {
  levelRules: {
    [classesTypes.barbarian]: barbarianRules,
    [classesTypes.sorceress]: sorceressRules,
    [classesTypes.amazon]: amazonRules,
    [classesTypes.assasin]: assasinRules,
    [classesTypes.druid]: druidRules,
    [classesTypes.paladin]: paladinRules,
    [classesTypes.necromancer]: necromancerRules,
  },
  phaseRules: {
    [ruleClasses.gearRule]: {
      rules: gearRules,
      maxAmount: 5,
    },
    [ruleClasses.generalRule]: {
      rules: generalRules,
      maxAmount: 5,
    },
    [ruleClasses.gearStatRule]: {
      rules: gearStatRules,
      maxAmount: 10,
    },
    [ruleClasses.usablesRule]: {
      rules: usablesRules,
      maxAmount: 3,
    },
  },
};

module.exports = rules;
