import { useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import InfoField from '../../baseComponents/InfoField';

const PhaseText = styled.div`
  font-size: 32px;
  color: ${colors.primaryText};
`;

const CurrentPhase = () => {
  const { currentPhase } = useSelector((state) => state.game);

  return <PhaseText>{currentPhase}</PhaseText>;
};

export default CurrentPhase;
