import types from './types';

const addRules = (data) => ({
  type: types.ADD_RULES,
  data,
});

const updateExcludedSkills = (data) => ({
  type: types.UPDATE_EXCLUDED_SKILLS,
  data,
});

const updateIncludedSkills = (data) => ({
  type: types.UPDATE_EXCLUDED_SKILLS,
  data,
});

const rollRule = (data) => ({
  type: types.ROLL_RULE,
  data,
});

const rollNewRules = (data) => ({
  type: types.ROLL_NEW_RULES,
  data,
});

const setRolledNewRules = (data) => ({
  type: types.SET_ROLLED_NEW_RULES,
  data,
});

const setRuleToRoll = (data) => ({
  type: types.SET_RULE_TO_ROLL,
  data,
});

const setSelectedRule = (data) => ({
  type: types.SET_SELECTED_RULE,
  data,
});

const chooseSelectedRule = (data) => ({
  type: types.CHOOSE_SELECTED_RULE,
  data,
});

const restartGame = (data) => ({
  type: types.RESTART_GAME,
  data,
});

const restoreRules = (data) => ({
  type: types.RESTORE_RULES,
  data,
});

export default {
  addRules,
  updateExcludedSkills,
  updateIncludedSkills,
  rollRule,
  rollNewRules,
  setRolledNewRules,
  setRuleToRoll,
  setSelectedRule,
  chooseSelectedRule,
  restartGame,
  restoreRules,
};
