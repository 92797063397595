import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getPhaseGoalsForCurrentPhase } from '../../../redux/Game/selectors';
import PhaseGoal from './PhaseGoal';
import Title from '../../baseComponents/Title';
import { GameActions } from '../../../redux/Game';

const Container = styled.div`
  align-content: center;
  justify-content: center;
  margin-bottom: ${({ useStreamerLayout }) =>
    useStreamerLayout ? '0px' : '32px'};
`;

const PhaseGoals = () => {
  const dispatch = useDispatch();
  const goals = useSelector((state) => getPhaseGoalsForCurrentPhase(state));
  const {
    game: { controlledPlayer, players },
    settings: { useStreamerLayout },
  } = useSelector((state) => state);

  let phaseGoals = [];
  const disabled = players.length > 1 && controlledPlayer !== 0;
  phaseGoals = goals.map((goal) => {
    let addidionalGoals = [];
    if (goal?.additionalGoals?.length > 0) {
      addidionalGoals = goal.additionalGoals.map(
        (additionalGoal, childIndex) => {
          return (
            <PhaseGoal
              key={additionalGoal.name}
              phaseGoal={additionalGoal}
              useStreamerLayout={useStreamerLayout}
              onChange={() =>
                dispatch(
                  GameActions.setAdditionalPhaseGoalState({
                    goal,
                    childIndex,
                  }),
                )
              }
              disabled={disabled}
            />
          );
        },
      );
    }
    return (
      <div key={goal.id}>
        <PhaseGoal
          key={goal.id}
          phaseGoal={goal}
          onChange={() => dispatch(GameActions.setPhaseGoalState(goal))}
          disabled={disabled}
          useStreamerLayout={useStreamerLayout}
        />
        {addidionalGoals}
      </div>
    );
  });

  return (
    <Container useStreamerLayout={useStreamerLayout}>
      {!useStreamerLayout && <Title text="Phase Goals" />}
      {phaseGoals}
    </Container>
  );
};

export default PhaseGoals;
