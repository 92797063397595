import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameActions } from '../../../redux/Game';
import BaseSelector from '../../baseSelector';

const ControlledPlayerSelector = () => {
  const dispatch = useDispatch();
  const {
    user: { sessionToken },
    game: { players, controlledPlayer },
    settings: { numberOfPlayers },
  } = useSelector((state) => state);

  const selectControlled = (data) => {
    dispatch(GameActions.setControlledPlayer(Number(data.target.value)));
  };

  const options = [];
  for (let p = 0; p < numberOfPlayers; p += 1) {
    options.push({
      name: players[p].displayName + (p === 0 ? ' (Leader)' : ''),
      value: p,
    });
  }

  return (
    <>
      {sessionToken && numberOfPlayers > 1 && (
        <BaseSelector
          label="Controlled Player"
          options={options}
          onChange={selectControlled}
          value={controlledPlayer}
        />
      )}
    </>
  );
};

export default ControlledPlayerSelector;
