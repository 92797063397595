const getRulesForCurrentPhase = (state) => {
  return state.game.players[state.game.controlledPlayer].rules.filter(
    (rule) => rule.phase === state.game.currentPhase,
  );
};

const getNeededTokensToRoll = (state) => {
  return state.game.rulesRerollCount * 10 + 10;
};

export { getRulesForCurrentPhase, getNeededTokensToRoll };
