import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store, { persistor } from './redux/createStore';
import App from './App';
import { release } from './release';

const isProduction = process.env.NODE_ENV === 'production';
Sentry.init({
  dsn: 'https://1bea05052063466dbe5cbde3ae902fcb@o477048.ingest.sentry.io/6753749',
  integrations: [new Integrations.BrowserTracing()],
  environment: isProduction ? 'production' : 'development',
  release: release.version,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);
