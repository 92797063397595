import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Title from '../../baseComponents/Title';
import BaseButton from '../../baseButton';
import LoadingSpinner from './LoadingSpinner';
import { SettingsActions } from '../../../redux/Settings';
import { GameActions } from '../../../redux/Game';
import colors from '../../../utils/colors';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding-bottom: 24px;
  width: 60%;
  max-width: 650px;
  border: 2px solid ${colors.grey[3]};
  border-radius: 8px;
  background-color: ${colors.grey[1]};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const Change = styled.p`
  font-size: 22px;
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  margin-top: 30px;
`;

const PhaseText = styled.p`
  margin: 30px auto 10px auto;
  font-size: 12px;
  width: 250px;
  color: ${colors.secondaryText};
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
`;

const ModalButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 70px;
  margin-right: 70px;
`;

const LevelGapModal = () => {
  const dispatch = useDispatch();
  const controlledPlayer = useSelector((state) => state.game.controlledPlayer);

  const { availableLevelRuleCount, lastLevelGapReached, skillRules } =
    useSelector((state) => state.game.players[controlledPlayer]);
  const lastRolledLevelRule = skillRules.filter(
    (rule) => rule?.level === lastLevelGapReached,
  );

  const { phaseLoading, rulesRerollCount, tokens } = useSelector(
    (state) => state.game,
  );

  let newRules = [];
  newRules = lastRolledLevelRule.map((rule) => {
    return rule && <Change key={rule.id}>{rule.name}</Change>;
  });

  const ruleRollCost = rulesRerollCount * 10 + 10;

  const rollAgainButtonText = 'Roll again! (' + ruleRollCost + ' tokens)';

  return (
    <Container>
      <Modal>
        {phaseLoading ? (
          <LoadingSpinner />
        ) : (
          <VerticalContainer>
            <Title text="New level gap reached" />
            {lastRolledLevelRule.length > 0 ? (
              <SmallTitle>New Level rule:</SmallTitle>
            ) : (
              <SmallTitle>Sorry. no skills anymore :/</SmallTitle>
            )}
            {newRules}
            {lastRolledLevelRule.length > 0 && (
              <PhaseText>
                This is the only time you can try to re roll a skill rule to
                something different. Choose wisely!
              </PhaseText>
            )}
            {availableLevelRuleCount === 1 ? (
              <PhaseText>
                Buuut .. there is no other skill to roll. Deal with it.
              </PhaseText>
            ) : availableLevelRuleCount === 0 ? (
              <PhaseText>
                No available rules to roll. yay!... no wait!
              </PhaseText>
            ) : (
              <>
                {tokens >= ruleRollCost ? (
                  <>
                    {lastRolledLevelRule.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <PhaseText>
                          There is {availableLevelRuleCount} possible rules to
                          roll.
                        </PhaseText>
                        <ModalButtonContainer>
                          <BaseButton
                            size="18"
                            text={rollAgainButtonText}
                            onClick={() =>
                              dispatch(GameActions.rerollLastPlayerSkillRule())
                            }
                          />
                        </ModalButtonContainer>
                      </>
                    )}
                  </>
                ) : (
                  <PhaseText>
                    Feels bad man. You do not have enough tokens to try to roll
                    this rule to something else :(
                  </PhaseText>
                )}
              </>
            )}
            <ModalButtonContainer>
              <BaseButton
                size="18"
                text="Continue"
                onClick={() =>
                  dispatch(SettingsActions.setShowLevelPhaseModal(false))
                }
              />
            </ModalButtonContainer>
          </VerticalContainer>
        )}
      </Modal>
    </Container>
  );
};

export default LevelGapModal;
