import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameActions } from '../../../redux/Game';
import { allCurrentPhaseGoalsDone } from '../../../redux/Game/selectors';
import { UserActions } from '../../../redux/User';
import BaseButton from '../../baseButton';

const NextPhaseButton = () => {
  const {
    game: { controlledPlayer },
    user: { sessionToken },
  } = useSelector((state) => state);
  const allDone = useSelector((state) => allCurrentPhaseGoalsDone(state));
  const dispatch = useDispatch();

  const loadNextPhase = useCallback(
    () => dispatch(GameActions.nextPhase()),
    [],
  );
  const loadSession = useCallback(
    () => dispatch(UserActions.loadSession(sessionToken)),
    [sessionToken],
  );

  return (
    <>
      {Number(controlledPlayer) === 0 ? (
        <BaseButton
          onClick={loadNextPhase}
          alt="Start the game and roll some rules!"
          size="16"
          text="Next Phase"
          disabled={!allDone}
        />
      ) : (
        <BaseButton onClick={loadSession} alt="" size="16" text="Refresh" />
      )}
    </>
  );
};

export default NextPhaseButton;
