import { fork, call, takeEvery, put, select, all } from 'redux-saga/effects';
import { SettingsActions } from '../Settings';
import { RulesActions } from '../Rules';
import { GameActions } from '../Game';
import { SessionsActions } from '../Sessions';
import UserActions from './actions';
import types from './types';

function* handleCreateSession(context) {
  const { apiService, toast } = context;
  const { user, settings, game, rules } = yield select((state) => state);
  const saved = {
    state: {
      settings,
      user: { ...user, tokens: 0, sessionName: user.newSessionName },
      game,
      rules,
    },
  };

  const sessionToken = yield call(apiService.createSession, saved);
  if (sessionToken !== null) {
    yield put(UserActions.setSessionToken(sessionToken));
    yield put(UserActions.setSessionName(user.newSessionName));
  } else {
    toast.error('Failed to create session');
  }
}

function* handleLoadSession(context, sessionToken) {
  const { apiService, toast } = context;
  yield put(GameActions.setPhaseLoading(true));
  const {
    user: { loadSessionInput },
    game: { currentPhase },
    settings: { numberOfPlayers },
  } = yield select((state) => state);

  const session = yield call(
    apiService.loadSession,
    sessionToken.data || loadSessionInput,
  );
  if (session !== null) {
    if (numberOfPlayers > session.state.settings.numberOfPlayers) {
      yield all([
        put(UserActions.restoreUser(session.state.user)),
        put(SettingsActions.restoreSettings(session.state.settings)),
        put(GameActions.restoreGame(session.state.game)),
        put(RulesActions.restoreRules(session.state.rules)),
      ]);
    } else {
      yield all([
        put(UserActions.restoreUser(session.state.user)),
        put(GameActions.restoreGame(session.state.game)),
        put(SettingsActions.restoreSettings(session.state.settings)),
        put(RulesActions.restoreRules(session.state.rules)),
      ]);
    }
    yield put(SettingsActions.setShowSessionMenu(false));
    if (currentPhase < session.state.game.currentPhase) {
      yield put(SettingsActions.setShowPhaseModal(true));
    }
    yield put(
      SessionsActions.addSession({
        sessionName: session.state.user.sessionName,
        sessionToken: sessionToken.data || loadSessionInput,
      }),
    );
  } else {
    toast.error('Failed to load session');
  }
  yield put(GameActions.setPhaseLoading(false));
}
function* handleExitSession() {
  yield put(UserActions.restartGame());
  yield put(UserActions.clearSession());
  yield put(SettingsActions.restartGame());
  yield put(GameActions.resetGame());
  yield put(RulesActions.restartGame());
}

function* watchCreateSession(context) {
  yield takeEvery(types.CREATE_SESSION, handleCreateSession, context);
}

function* watchLoadSession(context) {
  yield takeEvery(types.LOAD_SESSION, handleLoadSession, context);
}

function* watchExitSession(context) {
  yield takeEvery(types.EXIT_SESSION, handleExitSession, context);
}

export default (context) => [
  fork(watchCreateSession, context),
  fork(watchLoadSession, context),
  fork(watchExitSession, context),
];
