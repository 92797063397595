import rules from "../../../functions/nextPhase/data/rules/rules";

export default {
  numberOfPlayers: 1,
  initialRuleCount: 3,
  phaseRuleCount: 1,
  showIncludedSkills: false,
  showPhaseModal: false,
  showLevelPhaseModal: false,
  showRuleRollModal: false,
  showSessionToken: false,
  showSessionMenu: false,
  showRestartGameModal: false,
  saveToHallOfFame: false,
  hallToFameComment: '',
  showAdvancedSettings: false,
  advancedSettings: {
    phaseRules: rules.phaseRules
  },
  useStreamerLayout: false,
  groupRules: false,
};
