const RULE_NO_ICE_BOLT = 'RULE_NO_ICE_BOLT';
const RULE_NO_FROZEN_ARMOR = 'RULE_NO_FROZEN_ARMOR';
const RULE_NO_CHARGED_BOLT = 'RULE_NO_CHARGED_BOLT';
const RULE_NO_FIRE_BOLT = 'RULE_NO_FIRE_BOLT';
const RULE_NO_WARMTH = 'RULE_NO_WARMTH';

const RULE_NO_FROST_NOVA = 'RULE_NO_FROST_NOVA';
const RULE_NO_ICE_BLAST = 'RULE_NO_ICE_BLAST';
const RULE_NO_STATIC_FIELD = 'RULE_NO_STATIC_FIELD';
const RULE_NO_TELEKINESIS = 'RULE_NO_TELEKINESIS';
const RULE_NO_INFERNO = 'RULE_NO_INFERNO';

const RULE_NO_SHIVER_ARMOR = 'RULE_NO_SHIVER_ARMOR';
const RULE_NO_NOVA = 'RULE_NO_NOVA';
const RULE_NO_LIGHTNING = 'RULE_NO_LIGHTNING';
const RULE_NO_BLAZE = 'RULE_NO_BLAZE';
const RULE_NO_FIRE_BALL = 'RULE_NO_FIRE_BALL';

const RULE_NO_GLACIAL_SPIKE = 'RULE_NO_GLACIAL_SPIKE';
const RULE_NO_CHAIN_LIGHTNING = 'RULE_NO_CHAIN_LIGHTNING';
const RULE_NO_TELEPORT = 'RULE_NO_TELEPORT';
const RULE_NO_FIRE_WALL = 'RULE_NO_FIRE_WALL';
const RULE_NO_ENCHANT = 'RULE_NO_ENCHANT';

const RULE_NO_BLIZZARD = 'RULE_NO_BLIZZARD';
const RULE_NO_CHILLING_ARMOR = 'RULE_NO_CHILLING_ARMOR';
const RULE_NO_THUNDER_STORM = 'RULE_NO_THUNDER_STORM';
const RULE_NO_ENERGY_SHIELD = 'RULE_NO_ENERGY_SHIELD';
const RULE_NO_METEOR = 'RULE_NO_METEOR';

const RULE_NO_FROZEN_ORB = 'RULE_NO_FROZEN_ORB';
const RULE_NO_COLD_MASTERY = 'RULE_NO_COLD_MASTERY';
const RULE_NO_LIGHTNING_MASTERY = 'RULE_NO_LIGHTNING_MASTERY';
const RULE_NO_FIRE_MASTERY = 'RULE_NO_FIRE_MASTERY';
const RULE_NO_HYDRA = 'RULE_NO_HYDRA';

const sorceressRules = [
  {
    id: RULE_NO_ICE_BOLT,
    name: 'Without assigning points to Ice Bolt skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_ICE_BLAST,
      RULE_NO_GLACIAL_SPIKE,
      RULE_NO_BLIZZARD,
      RULE_NO_FROZEN_ORB,
    ],
  },
  {
    id: RULE_NO_FROZEN_ARMOR,
    name: 'Without assigning points to Frozen Armor skill',
    level: 1,
    deactivatesSkills: [RULE_NO_SHIVER_ARMOR, RULE_NO_CHILLING_ARMOR],
  },
  {
    id: RULE_NO_CHARGED_BOLT,
    name: 'Without assigning points to Charged Bolt skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_LIGHTNING,
      RULE_NO_CHAIN_LIGHTNING,
      RULE_NO_THUNDER_STORM,
      RULE_NO_ENERGY_SHIELD,
    ],
  },
  {
    id: RULE_NO_FIRE_BOLT,
    name: 'Without assigning points to Fire Bolt skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_FIRE_BALL,
      RULE_NO_ENCHANT,
      RULE_NO_METEOR,
      RULE_NO_HYDRA,
    ],
  },
  {
    id: RULE_NO_WARMTH,
    name: 'Without assigning points to Warmth skill',
    level: 1,
    deactivatesSkills: [RULE_NO_ENCHANT, RULE_NO_HYDRA],
  },
  {
    id: RULE_NO_FROST_NOVA,
    name: 'Without assigning points to Frost Nova skill',
    level: 6,
    deactivatesSkills: [RULE_NO_BLIZZARD, RULE_NO_FROZEN_ORB],
  },
  {
    id: RULE_NO_ICE_BLAST,
    name: 'Without assigning points to Ice Blast skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_GLACIAL_SPIKE,
      RULE_NO_BLIZZARD,
      RULE_NO_FROZEN_ORB,
    ],
  },
  {
    id: RULE_NO_STATIC_FIELD,
    name: 'Without assigning points to Static Field skill',
    level: 6,
    deactivatesSkills: [RULE_NO_NOVA, RULE_NO_THUNDER_STORM],
  },
  {
    id: RULE_NO_TELEKINESIS,
    name: 'Without assigning points to Telekinesis skill',
    level: 6,
    deactivatesSkills: [RULE_NO_TELEPORT, RULE_NO_ENERGY_SHIELD],
  },
  {
    id: RULE_NO_INFERNO,
    name: 'Without assigning points to Inferno skill',
    level: 6,
    deactivatesSkills: [RULE_NO_BLAZE, RULE_NO_FIRE_WALL, RULE_NO_METEOR],
  },
  {
    id: RULE_NO_SHIVER_ARMOR,
    name: 'Without assigning points to Shiver Armor skill',
    level: 12,
    deactivatesSkills: [RULE_NO_CHILLING_ARMOR],
  },
  {
    id: RULE_NO_NOVA,
    name: 'Without assigning points to Nova skill',
    level: 12,
    deactivatesSkills: [RULE_NO_THUNDER_STORM],
  },
  {
    id: RULE_NO_LIGHTNING,
    name: 'Without assigning points to Lightning skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_CHAIN_LIGHTNING,
      RULE_NO_THUNDER_STORM,
      RULE_NO_ENERGY_SHIELD,
    ],
  },
  {
    id: RULE_NO_BLAZE,
    name: 'Without assigning points to Blaze skill',
    level: 12,
    deactivatesSkills: [RULE_NO_FIRE_WALL, RULE_NO_METEOR],
  },
  {
    id: RULE_NO_FIRE_BALL,
    name: 'Without assigning points to Fire Ball skill',
    level: 12,
    deactivatesSkills: [RULE_NO_HYDRA, RULE_NO_ENCHANT, RULE_NO_METEOR],
  },
  {
    id: RULE_NO_GLACIAL_SPIKE,
    name: 'Without assigning points to Glacial Spike skill',
    level: 18,
    deactivatesSkills: [RULE_NO_BLIZZARD, RULE_NO_FROZEN_ORB],
  },
  {
    id: RULE_NO_CHAIN_LIGHTNING,
    name: 'Without assigning points to Chain Lightning skill',
    level: 18,
    deactivatesSkills: [RULE_NO_THUNDER_STORM, RULE_NO_ENERGY_SHIELD],
  },
  {
    id: RULE_NO_TELEPORT,
    name: 'Without assigning points to Teleport skill',
    level: 18,
    deactivatesSkills: [RULE_NO_ENERGY_SHIELD],
  },
  {
    id: RULE_NO_FIRE_WALL,
    name: 'Without assigning points to Fire Wall skill',
    level: 18,
    deactivatesSkills: [RULE_NO_METEOR],
  },
  {
    id: RULE_NO_ENCHANT,
    name: 'Without assigning points to Enchant skill',
    level: 18,
    deactivatesSkills: [RULE_NO_HYDRA],
  },
  {
    id: RULE_NO_BLIZZARD,
    name: 'Without assigning points to Blizzard skill',
    level: 24,
    deactivatesSkills: [RULE_NO_FROZEN_ORB],
  },
  {
    id: RULE_NO_CHILLING_ARMOR,
    name: 'Without assigning points to Chilling Armor skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_THUNDER_STORM,
    name: 'Without assigning points to Thunder Storm skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_ENERGY_SHIELD,
    name: 'Without assigning points to Energy Shield skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_METEOR,
    name: 'Without assigning points to Meteor skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FROZEN_ORB,
    name: 'Without assigning points to Frozen Orb skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_COLD_MASTERY,
    name: 'Without assigning points to Cold Mastery skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_LIGHTNING_MASTERY,
    name: 'Without assigning points to Lightning Mastery skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FIRE_MASTERY,
    name: 'Without assigning points to Fire Mastery skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_HYDRA,
    name: 'Without assigning points to Hydra skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = sorceressRules;
