import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faRedo,
  faSkull,
  faBars,
  faSignOutAlt,
  faCoins,
  faStar,
  faMoneyCheckDollar,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { GameActions } from '../redux/Game';
import { UserActions } from '../redux/User';
import { SettingsActions } from '../redux/Settings';
import BaseSelector from './baseSelector';
import BaseInput from './baseInput';
import BaseButton from './baseButton';
import Checkbox from './roguelite/components/Checkbox';

const NavigationContainer = styled.div`
  height: 94px;
  display: flex;
  flex-direction: row;
  background-color: #0b0d12;
  align-items: center;
  position: sticky;
  top: 0px;
`;
const TitleContainer = styled.div`
  flex: 3;
  align-items: center;
  justify-content: center;
`;
const SideContainer = styled.div`
  display: flex;
  flex: 3;
  justify-content: ${(props) => props.direction};
  padding: 0 32px;
  height: 100%;
  align-items: center;
`;
const IconLink = styled(Link)`
  display: flex;
  justify-content: center;
  color: #d2c8ae;
  width: 3rem;
  margin: 8px;
  &:hover {
    color: white;
  }
`;
const Title = styled.p`
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: #d2c8ae;
  margin: 0;
`;

const Session = styled.p`
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #d2c8ae;
  margin: 0;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const animation = () =>
  css`
    ${rotate360} 0.5s linear infinite;
  `;

const Redo = styled.button`
  margin-left: 16px;
  margin-right: 16px;
  font-size: 36px;
  color: #d2c8ae;
  background: none;
  border: 0;
  animation: ${(props) => (props.spin ? animation : '')};

  &:hover {
    color: white;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const Tokens = styled.div`
  display: flex;
  flex-direction: column;
  color: #d2c8ae;
  margin-left: 16px;
  margin-right: 16px;
  justify-content: center;
`;

const TokenItem = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  align-items: center;
  gap: 8px;
`;

const SessionMenu = styled.div`
  height: 160px;
  display: flex;
  flex-direction: column;
  background-color: #0e1015;
  align-items: center;
  position: absolute;
  top: 94px;
  z-index: 10;
  width: 100%;
  color: #d2c8ae;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-content: center;
`;

const SessionSelector = styled(BaseSelector)`
  width: 170px;
`;

const Aligner = styled.p`
  display: flex;
  margin-top: 40px;
  align-items: center;
`;

const IconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: #d2c8ae;

  & > span {
    font-size: 16px;
    position: relative;
    top: -10px;
    display: none;
    z-index: 11;
  }

  :hover > span {
    display: flex;
  }
`;

const ExitButton = styled(IconButton)`
  margin-left: 5rem;
`;

const NavigationBar = () => {
  const dispatch = useDispatch();
  const {
    user: { sessionToken, sessionName, loadSessionInput },
    game: {
      players,
      tokens,
      rulesRerollCount,
      phaseLoading,
      controlledPlayer,
      score,
    },
    settings: {
      numberOfPlayers,
      showSessionToken,
      showSessionMenu,
      useStreamerLayout,
      groupRules,
    },
    sessions: { sessions },
  } = useSelector((state) => state);

  const options = [];
  for (let p = 0; p < numberOfPlayers; p += 1) {
    options.push({
      name: players[p].displayName + (p === 0 ? ' (Leader)' : ''),
      value: p,
    });
  }

  const sessionOptions = sessions.map((s) => {
    return {
      name: s.sessionName,
      value: s.sessionToken,
    };
  });

  sessionOptions.unshift({ name: 'Choose session', value: '' });

  const selectControlled = (data) => {
    dispatch(GameActions.setControlledPlayer(Number(data.target.value)));
  };

  const sessionText = showSessionToken
    ? sessionToken
    : sessionName || 'New session';

  const toggleShowSessionToken = () => {
    dispatch(SettingsActions.setShowSessionToken(!showSessionToken));
  };

  const handleOnChangeUseStreamerLayout = () => {
    dispatch(SettingsActions.setUseStreamerLayout(!useStreamerLayout));
  };

  const handleOnChangeGroupRules = () => {
    dispatch(SettingsActions.setGroupRules(!groupRules));
  };

  return (
    <>
      <NavigationContainer>
        <SideContainer direction="flex-start">
          <IconButton>
            <IconLink to="/" title="Home">
              <FontAwesomeIcon icon={faHome} />
            </IconLink>
            <span>Home</span>
          </IconButton>
          {sessionToken && numberOfPlayers > 1 && (
            <BaseSelector
              label="Controlled Player"
              options={options}
              onChange={selectControlled}
              value={controlledPlayer}
            />
          )}
          <IconButton>
            <Redo
              onClick={() => {
                dispatch(SettingsActions.setShowRestartGameModal(true));
              }}
              title="Start new character"
            >
              <FontAwesomeIcon icon={faSkull} />
            </Redo>
            <span>New Char</span>
          </IconButton>
          <IconButton>
            <Redo
              onClick={() => dispatch(UserActions.loadSession(sessionToken))}
              title="Refresh"
              spin={phaseLoading}
            >
              <FontAwesomeIcon icon={faRedo} />
            </Redo>
            <span>Refresh</span>
          </IconButton>
        </SideContainer>
        <TitleContainer>
          <Title>Diablo 2 Challenge runs</Title>
          <Session onClick={toggleShowSessionToken}>{sessionText}</Session>
        </TitleContainer>
        <SideContainer direction="flex-end">
          {sessionToken && (
            <>
              <ButtonContainer>
                <Tokens>
                  <TokenItem>
                    <FontAwesomeIcon
                      icon={faCoins}
                      title={'Your tokens. Use them wisely'}
                    />
                    {tokens}
                  </TokenItem>
                  <TokenItem>
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      title={'Next reroll cost'}
                    />
                    {rulesRerollCount * 10 + 10}
                  </TokenItem>
                </Tokens>
              </ButtonContainer>
              <ButtonContainer>
                <Tokens>
                  <TokenItem>
                    <FontAwesomeIcon icon={faStar} title={'Your score'} />
                    {score}
                  </TokenItem>
                </Tokens>
              </ButtonContainer>
            </>
          )}
          <Redo
            onClick={() => {
              dispatch(SettingsActions.setShowSessionMenu(!showSessionMenu));
            }}
            title="Open Session Menu"
          >
            <FontAwesomeIcon icon={faBars} />
          </Redo>
        </SideContainer>
      </NavigationContainer>
      {showSessionMenu && (
        <SessionMenu>
          <SideContainer direction="center">
            <SessionSelector
              label="Stored sessions"
              options={sessionOptions}
              onChange={(option) =>
                dispatch(UserActions.setLoadSessionInput(option.target.value))
              }
            />
            <Aligner>-&gt;</Aligner>
            <BaseInput
              key="LoadSessionInput"
              label="Load / Join session"
              value={loadSessionInput}
              onChange={(e) =>
                dispatch(UserActions.setLoadSessionInput(e.target.value))
              }
              nomargin
            />
            <Aligner>
              <BaseButton
                size="16"
                text="Load"
                onClick={() => {
                  dispatch(UserActions.loadSession(loadSessionInput));
                }}
              />
            </Aligner>
            {sessionToken !== '' && (
              <ExitButton>
                <Redo
                  onClick={() => {
                    dispatch(UserActions.exitSession());
                  }}
                  title="Exit current session"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </Redo>
                <span>Exit session</span>
              </ExitButton>
            )}
          </SideContainer>
          <SideContainer>
            <Checkbox
              label={'Use streaming layout'}
              checked={useStreamerLayout}
              onChange={handleOnChangeUseStreamerLayout}
            />
            <Checkbox
              label={'Group rules'}
              checked={groupRules}
              onChange={handleOnChangeGroupRules}
            />
          </SideContainer>
        </SessionMenu>
      )}
    </>
  );
};

export default NavigationBar;
