import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInput = styled.input`
  border-radius: 4px;
  border: 1px solid rgb(118, 118, 118);
  background-color: #444444;
  color: #ffffff;
  width: ${(props) => (props.width ? props.width : '20rem')};
  height: ${({ height }) => height ?? '33px'};
  text-align: center;
  text-align-last: center;
  padding: 0.25em;
  padding-left: 15px;
  padding-right: 15px;
  align-self: center;
`;

const Container = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.nomargin ? 0 : '2rem')};
  margin-bottom: ${(props) => (props.nomargin ? 0 : '0.5rem')};
`;

const Label = styled.label`
  margin-bottom: 0px;
  text-align: center;
  font-size: 1rem;
`;

const BaseInput = ({
  label,
  value,
  onChange,
  id,
  type,
  width,
  height,
  nomargin,
}) => {
  return (
    <Container nomargin={nomargin}>
      <Label htmlFor={id}>{label}</Label>
      <StyledInput
        id={id}
        onChange={onChange}
        value={value}
        type={type}
        width={width}
        height={height}
      />
    </Container>
  );
};

BaseInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  nomargin: PropTypes.bool,
};

export default BaseInput;
