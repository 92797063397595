const RULE_NO_HOWL = 'RULE_NO_HOWL';
const RULE_NO_FIND_POTION = 'RULE_NO_FIND_POTION';
const RULE_NO_SWORD_MASTERY = 'RULE_NO_SWORD_MASTERY';
const RULE_NO_AXE_MASTERY = 'RULE_NO_AXE_MASTERY';
const RULE_NO_MACE_MASTERY = 'RULE_NO_MACE_MASTERY';
const RULE_NO_BASH = 'RULE_NO_BASH';

const RULE_NO_TAUNT = 'RULE_NO_TAUNT';
const RULE_NO_SHOUT = 'RULE_NO_SHOUT';
const RULE_NO_POLEARM_MASTERY = 'RULE_NO_POLEARM_MASTERY';
const RULE_NO_THROWING_MASTERY = 'RULE_NO_THROWING_MASTERY';
const RULE_NO_SPEAR_MASTERY = 'RULE_NO_SPEAR_MASTERY';
const RULE_NO_LEAP = 'RULE_NO_LEAP';
const RULE_NO_DOUBLE_SWING = 'RULE_NO_DOUBLE_SWING';

const RULE_NO_FIND_ITEM = 'RULE_NO_FIND_ITEM';
const RULE_NO_INCREASED_STAMINA = 'RULE_NO_INCREASED_STAMINA';
const RULE_NO_STUN = 'RULE_NO_STUN';
const RULE_NO_DOUBLE_THROW = 'RULE_NO_DOUBLE_THROW';

const RULE_NO_BATTLE_CRY = 'RULE_NO_BATTLE_CRY';
const RULE_NO_IRON_SKIN = 'RULE_NO_IRON_SKIN';
const RULE_NO_LEAP_ATTACK = 'RULE_NO_LEAP_ATTACK';
const RULE_NO_CONCENTRATE = 'RULE_NO_CONCENTRATE';

const RULE_NO_BATTLE_ORDERS = 'RULE_NO_BATTLE_ORDERS';
const RULE_NO_GRIM_WARD = 'RULE_NO_GRIM_WARD';
const RULE_NO_INCREASED_SPEED = 'RULE_NO_INCREASED_SPEED';
const RULE_NO_FRENZY = 'RULE_NO_FRENZY';

const RULE_NO_WAR_CRY = 'RULE_NO_WAR_CRY';
const RULE_NO_BATTLE_COMMAND = 'RULE_NO_BATTLE_COMMAND';
const RULE_NO_NATURAL_RESISTANCE = 'RULE_NO_NATURAL_RESISTANCE';
const RULE_NO_WHIRLWIND = 'RULE_NO_WHIRLWIND';
const RULE_NO_BERSERK = 'RULE_NO_BERSERK';

const barbarianRules = [
  {
    id: RULE_NO_HOWL,
    name: 'Without assigning points to Howl skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_TAUNT,
      RULE_NO_SHOUT,
      RULE_NO_BATTLE_CRY,
      RULE_NO_BATTLE_ORDERS,
      RULE_NO_WAR_CRY,
      RULE_NO_BATTLE_COMMAND,
    ],
  },
  {
    id: RULE_NO_FIND_POTION,
    name: 'Without assigning points to Find Potion skill',
    level: 1,
    deactivatesSkills: [RULE_NO_FIND_ITEM, RULE_NO_GRIM_WARD],
  },
  {
    id: RULE_NO_SWORD_MASTERY,
    name: 'Without assigning points to Sword Mastery skill',
    level: 1,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_AXE_MASTERY,
    name: 'Without assigning points to Axe Mastery skill',
    level: 1,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_MACE_MASTERY,
    name: 'Without assigning points to Mace Mastery skill',
    level: 1,
    deactivatesSkills: [],
  },
/*  {
    id: RULE_NO_BASH,
    name: 'Without assigning points to Bash skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_DOUBLE_SWING,
      RULE_NO_STUN,
      RULE_NO_DOUBLE_THROW,
      RULE_NO_CONCENTRATE,
      RULE_NO_BERSERK,
      RULE_NO_FRENZY,
      RULE_NO_WHIRLWIND,
    ],
  },*/
  {
    id: RULE_NO_TAUNT,
    name: 'Without assigning points to Taunt skill',
    level: 6,
    deactivatesSkills: [RULE_NO_BATTLE_CRY, RULE_NO_WAR_CRY],
  },
  {
    id: RULE_NO_SHOUT,
    name: 'Without assigning points to Shout skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_BATTLE_ORDERS,
      RULE_NO_BATTLE_COMMAND,
      RULE_NO_WAR_CRY,
    ],
  },
  {
    id: RULE_NO_POLEARM_MASTERY,
    name: 'Without assigning points to Polearm Mastery skill',
    level: 6,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_THROWING_MASTERY,
    name: 'Without assigning points to Throwing Mastery skill',
    level: 6,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_SPEAR_MASTERY,
    name: 'Without assigning points to Spear Mastery skill',
    level: 6,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_LEAP,
    name: 'Without assigning points to Leap skill',
    level: 6,
    deactivatesSkills: [RULE_NO_LEAP_ATTACK, RULE_NO_WHIRLWIND],
  },
  {
    id: RULE_NO_DOUBLE_SWING,
    name: 'Without assigning points to Double Swing skill',
    level: 6,
    deactivatesSkills: [RULE_NO_DOUBLE_THROW, RULE_NO_FRENZY],
  },
  {
    id: RULE_NO_FIND_ITEM,
    name: 'Without assigning points to Find Item skill',
    level: 12,
    deactivatesSkills: [RULE_NO_GRIM_WARD],
  },
  {
    id: RULE_NO_INCREASED_STAMINA,
    name: 'Without assigning points to Increased Stamina skill',
    level: 12,
    deactivatesSkills: [RULE_NO_INCREASED_SPEED],
  },
  {
    id: RULE_NO_STUN,
    name: 'Without assigning points to Stun skill',
    level: 12,
    deactivatesSkills: [
      RULE_NO_CONCENTRATE,
      RULE_NO_WHIRLWIND,
      RULE_NO_BERSERK,
    ],
  },
  {
    id: RULE_NO_DOUBLE_THROW,
    name: 'Without assigning points to Double Throw skill',
    level: 12,
    deactivatesSkills: [RULE_NO_FRENZY],
  },
  {
    id: RULE_NO_BATTLE_CRY,
    name: 'Without assigning points to Battle Cry skill',
    level: 18,
    deactivatesSkills: [RULE_NO_WAR_CRY],
  },
  {
    id: RULE_NO_IRON_SKIN,
    name: 'Without assigning points to Iron Skin skill',
    level: 18,
    deactivatesSkills: [RULE_NO_NATURAL_RESISTANCE],
  },
  {
    id: RULE_NO_LEAP_ATTACK,
    name: 'Without assigning points to Leap Attack skill',
    level: 18,
    deactivatesSkills: [RULE_NO_WHIRLWIND],
  },
  {
    id: RULE_NO_CONCENTRATE,
    name: 'Without assigning points to Concentrate skill',
    level: 18,
    deactivatesSkills: [RULE_NO_WHIRLWIND, RULE_NO_BERSERK],
  },
  {
    id: RULE_NO_BATTLE_ORDERS,
    name: 'Without assigning points to Battle Orders skill',
    level: 24,
    deactivatesSkills: [RULE_NO_WAR_CRY, RULE_NO_BATTLE_COMMAND],
  },
  {
    id: RULE_NO_GRIM_WARD,
    name: 'Without assigning points to Grim Ward skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_INCREASED_SPEED,
    name: 'Without assigning points to Increased Speed skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_FRENZY,
    name: 'Without assigning points to Frenzy skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_WAR_CRY,
    name: 'Without assigning points to War Cry skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_BATTLE_COMMAND,
    name: 'Without assigning points to Battle Command skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_NATURAL_RESISTANCE,
    name: 'Without assigning points to Natural Resistance skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_WHIRLWIND,
    name: 'Without assigning points to Whirlwind skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_BERSERK,
    name: 'Without assigning points to Berserk skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = barbarianRules;
