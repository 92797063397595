const { ruleClasses } = require('../types/rules');

const NO_CRUSHING_BLOW = 'NO_CRUSHING_BLOW';
const NO_FASTER_CAST_RATE = 'NO_FASTER_CAST_RATE';
const NO_FASTER_HIT_RECOVERY = 'NO_FASTER_HIT_RECOVERY';
const NO_DEADLY_STRIKE = 'NO_DEADLY_STRIKE';
const NO_INCREASED_ATTACK_SPEED = 'NO_INCREASED_ATTACK_SPEED';
const NO_PLUS_MAX_DAMAGE = 'NO_PLUS_MAX_DAMAGE';
const NO_PLUS_MIN_DAMAGE = 'NO_PLUS_MIN_DAMAGE';
const NO_FASTER_RUN_WALK = 'NO_FASTER_RUN_WALK';
const NO_LIFE_LEECH = 'NO_LIFE_LEECH';
const NO_MANA_LEECH = 'NO_MANA_LEECH';
const NO_MANA_AFTER_KILL = 'NO_MANA_AFTER_KILL';
const NO_PLUS_LIFE_OR_VITALITY = 'NO_PLUS_LIFE_OR_VITALITY';
const NO_PLUS_MANA_OR_ENERGY = 'NO_PLUS_MANA_OR_ENERGY';
const NO_PLUS_ATTACK_RATING = 'NO_PLUS_ATTACK_RATING';
const NO_PLUS_CHANGE_TO_OPEN_WOUNDS = 'NO_PLUS_CHANGE_TO_OPEN_WOUNDS';

const gearStatRules = [
  {
    id: NO_CRUSHING_BLOW,
    name: 'Without using "n % Change of Crushing Blow" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.05,
    extraTokens: 0,
  },
  {
    id: NO_FASTER_CAST_RATE,
    name: 'Without using "Faster Cast Rate" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.12,
    extraTokens: 2,
  },
  {
    id: NO_FASTER_HIT_RECOVERY,
    name: 'Without using "Faster Hit Recovery" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.18,
    extraTokens: 3,
  },
  {
    id: NO_DEADLY_STRIKE,
    name: 'Without using "Deadly Strike" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1,
    extraTokens: 0,
  },
  {
    id: NO_INCREASED_ATTACK_SPEED,
    name: 'Without using "Increased Attack Speed" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.1,
    extraTokens: 2,
  },
  {
    id: NO_PLUS_MAX_DAMAGE,
    name: 'Without using "+ n Maximum Damage" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.03,
    extraTokens: 0,
  },
  {
    id: NO_PLUS_MIN_DAMAGE,
    name: 'Without using "+ n Minimum Damage" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.03,
    extraTokens: 0,
  },
  {
    id: NO_FASTER_RUN_WALK,
    name: 'Without using "Faster Run/Walk" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.12,
    extraTokens: 2,
  },
  {
    id: NO_LIFE_LEECH,
    name: 'Without using "n% Life Stolen per Hit" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.03,
    extraTokens: 0,
  },
  {
    id: NO_MANA_LEECH,
    name: 'Without using "n% Mana Stolen per Hit" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.02,
    extraTokens: 0,
  },
  {
    id: NO_MANA_AFTER_KILL,
    name: 'Without using "n Mana after kill" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1.04,
    extraTokens: 0,
  },
  {
    id: NO_PLUS_LIFE_OR_VITALITY,
    name: 'Without using "n Vitality or Life" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.15,
    extraTokens: 3,
  },
  {
    id: NO_PLUS_MANA_OR_ENERGY,
    name: 'Without using "n Mana or Energy" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.06,
    extraTokens: 1,
  },
  {
    id: NO_PLUS_ATTACK_RATING,
    name: 'Without using "+ n Attack Rating" stat on gear',
    type: ruleClasses.gearStatRule,
    tooltip: 'You can still try to get it from charms if not restricted by another rule.',
    multiplier: 1.10,
    extraTokens: 2,
  },
  {
    id: NO_PLUS_CHANGE_TO_OPEN_WOUNDS,
    name: 'Without using "n % Change of Open Wounds" stat on gear',
    type: ruleClasses.gearStatRule,
    multiplier: 1,
    extraTokens: 0,
  },
];

module.exports = gearStatRules;
