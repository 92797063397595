import produce from 'immer';
import types from './types';
import initialState from './initialState';

export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_NUMBER_OF_PLAYERS: {
      draft.numberOfPlayers = data.numberOfPlayers;

      break;
    }

    case types.SET_SESSION_TOKEN: {
      draft.sessionToken = data;

      break;
    }

    case types.SET_SESSION_NAME: {
      draft.sessionName = data;

      break;
    }

    case types.CLEAR_SESSION: {
      draft.sessionToken = '';
      draft.sessionName = '';

      break;
    }

    case types.SET_SHOW_LOGIN_MODAL: {
      draft.showLoginModal = data;

      break;
    }

    case types.SET_NEW_SESSION_NAME: {
      draft.newSessionName = data;

      break;
    }

    case types.SET_LOAD_SESSION_INPUT: {
      draft.loadSessionInput = data;

      break;
    }

    case types.RESTART_GAME: {
      draft = initialState;

      break;
    }

    case types.RESTORE_USER: {
      draft.loadSessionInput = '';
      draft.newSessionName = '';
      draft.showLoginModal = false;
      draft.sessionToken = data.sessionToken;
      draft.sessionName = data.sessionName;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
