import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../utils/colors';

const Button = styled.button`
  font-size: ${({ size }) => size ?? ''}px;
  border-radius: 0.25em;
  background-color: ${colors.primary};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  color: ${colors.primaryText};
  min-width: 8em;
  padding: 0.25em;
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid ${colors.primaryActive};
`;

const BaseButton = ({ onClick, text, alt, size, disabled }) => {
  return (
    <Button onClick={onClick} alt={alt} size={size} disabled={disabled}>
      {text}
    </Button>
  );
};

BaseButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseButton;
