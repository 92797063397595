const RULE_NO_SKELETON_MASTERY = 'RULE_NO_SKELETON_MASTERY';
const RULE_NO_RAISE_SKELETON = 'RULE_NO_RAISE_SKELETON';
const RULE_NO_TEETH = 'RULE_NO_TEETH';
const RULE_NO_BONE_ARMOR = 'RULE_NO_BONE_ARMOR';
const RULE_NO_AMPLIFY_DAGAMAGE = 'RULE_NO_AMPLIFY_DAGAMAGE';

const RULE_NO_CLAY_GOLEM = 'RULE_NO_CLAY_GOLEM';
const RULE_NO_POISON_DAGGER = 'RULE_NO_POISON_DAGGER';
const RULE_NO_CORPSE_EXPLOSION = 'RULE_NO_CORPSE_EXPLOSION';
const RULE_NO_DIM_VISION = 'RULE_NO_DIM_VISION';
const RULE_NO_WEAKEN = 'RULE_NO_WEAKEN';

const RULE_NO_GOLEM_MASTERY = 'RULE_NO_GOLEM_MASTERY';
const RULE_NO_RAISE_SKELETAL_MAGE = 'RULE_NO_RAISE_SKELETAL_MAGE';
const RULE_NO_BONE_WALL = 'RULE_NO_BONE_WALL';
const RULE_NO_IRON_MAIDEN = 'RULE_NO_IRON_MAIDEN';
const RULE_NO_TERROR = 'RULE_NO_TERROR';

const RULE_NO_BLOOD_GOLEM = 'RULE_NO_BLOOD_GOLEM';
const RULE_NO_POISON_EXPLOSION = 'RULE_NO_POISON_EXPLOSION';
const RULE_NO_BONE_SPREAR = 'RULE_NO_BONE_SPREAR';
const RULE_NO_CONFUSE = 'RULE_NO_CONFUSE';
const RULE_NO_LIFE_TAP = 'RULE_NO_LIFE_TAP';

const RULE_NO_SUMMON_RESIST = 'RULE_NO_SUMMON_RESIST';
const RULE_NO_IRON_GOLEM = 'RULE_NO_IRON_GOLEM';
const RULE_NO_BONE_PRISON = 'RULE_NO_BONE_PRISON';
const RULE_NO_ATTRACT = 'RULE_NO_ATTRACT';
const RULE_NO_DECREPIFY = 'RULE_NO_DECREPIFY';

const RULE_NO_FIRE_GOLEM = 'RULE_NO_FIRE_GOLEM';
const RULE_NO_REVIVE = 'RULE_NO_REVIVE';
const RULE_NO_POISON_NOVA = 'RULE_NO_POISON_NOVA';
const RULE_NO_BONE_SPIRIT = 'RULE_NO_BONE_SPIRIT';
const RULE_NO_LOWER_RESIST = 'RULE_NO_LOWER_RESIST';

const necromancerRules = [
  {
    id: RULE_NO_SKELETON_MASTERY,
    name: 'Without assigning points to Skeleton Mastery skill',
    level: 1,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_RAISE_SKELETON,
    name: 'Without assigning points to Raise Skeleton skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_RAISE_SKELETAL_MAGE,
      RULE_NO_REVIVE,
      RULE_NO_SKELETON_MASTERY,
    ],
  },
  {
    id: RULE_NO_TEETH,
    name: 'Without assigning points to Teeth skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_CORPSE_EXPLOSION,
      RULE_NO_BONE_SPREAR,
      RULE_NO_POISON_EXPLOSION,
      RULE_NO_POISON_NOVA,
      RULE_NO_BONE_SPIRIT,
      RULE_NO_BONE_PRISON,
    ],
  },
  {
    id: RULE_NO_BONE_ARMOR,
    name: 'Without assigning points to Bone Armor skill',
    level: 1,
    deactivatesSkills: [RULE_NO_BONE_WALL, RULE_NO_BONE_PRISON],
  },
  {
    id: RULE_NO_AMPLIFY_DAGAMAGE,
    name: 'Without assigning points to Amplify Damage skill',
    level: 1,
    deactivatesSkills: [
      RULE_NO_WEAKEN,
      RULE_NO_IRON_MAIDEN,
      RULE_NO_TERROR,
      RULE_NO_LIFE_TAP,
      RULE_NO_DECREPIFY,
      RULE_NO_LOWER_RESIST,
    ],
  },
  {
    id: RULE_NO_CLAY_GOLEM,
    name: 'Without assigning points to Clay Golem skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_GOLEM_MASTERY,
      RULE_NO_BLOOD_GOLEM,
      RULE_NO_SUMMON_RESIST,
      RULE_NO_IRON_GOLEM,
      RULE_NO_FIRE_GOLEM,
      RULE_NO_REVIVE,
    ],
  },
  {
    id: RULE_NO_POISON_DAGGER,
    name: 'Without assigning points to Poison Dagger skill',
    level: 6,
    deactivatesSkills: [RULE_NO_POISON_EXPLOSION, RULE_NO_POISON_NOVA],
  },
  {
    id: RULE_NO_CORPSE_EXPLOSION,
    name: 'Without assigning points to Corpse Explosion skill',
    level: 6,
    deactivatesSkills: [
      RULE_NO_POISON_EXPLOSION,
      RULE_NO_POISON_NOVA,
      RULE_NO_BONE_SPREAR,
      RULE_NO_BONE_SPIRIT,
      RULE_NO_BONE_PRISON,
    ],
  },
  {
    id: RULE_NO_DIM_VISION,
    name: 'Without assigning points to Dim Vision skill',
    level: 6,
    deactivatesSkills: [RULE_NO_CONFUSE, RULE_NO_ATTRACT],
  },
  {
    id: RULE_NO_GOLEM_MASTERY,
    name: 'Without assigning points to Golem Mastery skill',
    level: 12,
    deactivatesSkills: [RULE_NO_SUMMON_RESIST],
  },
  {
    id: RULE_NO_RAISE_SKELETAL_MAGE,
    name: 'Without assigning points to Raise Skeletal Mage skill',
    level: 12,
    deactivatesSkills: [RULE_NO_REVIVE],
  },
  {
    id: RULE_NO_BONE_WALL,
    name: 'Without assigning points to Bone Wall skill',
    level: 12,
    deactivatesSkills: [RULE_NO_BONE_PRISON],
  },
  {
    id: RULE_NO_IRON_MAIDEN,
    name: 'Without assigning points to Iron Maiden skill',
    level: 12,
    deactivatesSkills: [RULE_NO_LIFE_TAP, RULE_NO_LOWER_RESIST],
  },
  {
    id: RULE_NO_BLOOD_GOLEM,
    name: 'Without assigning points to Blood Golem skill',
    level: 18,
    deactivatesSkills: [RULE_NO_IRON_GOLEM, RULE_NO_FIRE_GOLEM, RULE_NO_REVIVE],
  },
  {
    id: RULE_NO_POISON_EXPLOSION,
    name: 'Without assigning points to Poison Explosion skill',
    level: 18,
    deactivatesSkills: [RULE_NO_POISON_NOVA],
  },
  {
    id: RULE_NO_BONE_SPREAR,
    name: 'Without assigning points to Bone Spear skill',
    level: 18,
    deactivatesSkills: [RULE_NO_BONE_SPIRIT, RULE_NO_BONE_PRISON],
  },
  {
    id: RULE_NO_CONFUSE,
    name: 'Without assigning points to Confuse skill',
    level: 18,
    deactivatesSkills: [RULE_NO_ATTRACT],
  },
  {
    id: RULE_NO_LIFE_TAP,
    name: 'Without assigning points to Life Tap skill',
    level: 18,
    deactivatesSkills: [RULE_NO_LOWER_RESIST],
  },
  {
    id: RULE_NO_SUMMON_RESIST,
    name: 'Without assigning points to Summon Resist skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_IRON_GOLEM,
    name: 'Without assigning points to Iron Golem skill',
    level: 24,
    deactivatesSkills: [RULE_NO_FIRE_GOLEM, RULE_NO_REVIVE],
  },
  {
    id: RULE_NO_BONE_PRISON,
    name: 'Without assigning points to Bone Prison skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_ATTRACT,
    name: 'Without assigning points to Attract skill',
    level: 24,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_DECREPIFY,
    name: 'Without assigning points to Decrepify skill',
    level: 24,
    deactivatesSkills: [RULE_NO_LOWER_RESIST],
  },
  {
    id: RULE_NO_FIRE_GOLEM,
    name: 'Without assigning points to Fire Golem skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_REVIVE,
    name: 'Without assigning points to Revive skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_POISON_NOVA,
    name: 'Without assigning points to Poison Nova skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_BONE_SPIRIT,
    name: 'Without assigning points to Bone Spirit skill',
    level: 30,
    deactivatesSkills: [],
  },
  {
    id: RULE_NO_LOWER_RESIST,
    name: 'Without assigning points to Lower Resist skill',
    level: 30,
    deactivatesSkills: [],
  },
];

module.exports = necromancerRules;
