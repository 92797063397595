import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.h1`
  font-size: ${(props) => (props.size === 'S' ? '18px' : '24px')};
  color: #d2c8ae;
  margin-top: 24px;
  text-align: center;
  font-weight: normal;
`;

const Title = ({ text, size }) => {
  return <Text size={size}>{text}</Text>;
};

Title.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
};

export default Title;
