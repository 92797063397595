import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MainContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-basis: 100%;
  background-color: ${(props) => props.backgroungColor};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) =>
    props.flexDirection === 'column' ? 'center' : 'flex-start'};
  ${(props) =>
    props.flexDirection === 'column' ? 'min-height: calc(100vh - 94px)' : ''};
`;

const WithBackgroundImage = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-basis: 100%;
  background-image: url(${(props) => props.url ?? ''});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom 0px;
  flex-direction: ${(props) => props.flexDirection};
  background-size: auto calc(100vh - 128px);
`;

const BaseContainer = ({
  imageUrl,
  flexDirection,
  backgroungColor,
  children,
  fullHeight,
}) => {
  return (
    <MainContainer
      backgroungColor={backgroungColor}
      url={imageUrl}
      flexDirection={flexDirection}
      fullHeight={fullHeight}
    >
      {imageUrl ? (
        <WithBackgroundImage
          url={imageUrl}
          flexDirection={flexDirection}
          fullHeight={fullHeight}
        >
          {children}
        </WithBackgroundImage>
      ) : (
        <>{children}</>
      )}
    </MainContainer>
  );
};

BaseContainer.propTypes = {
  imageUrl: PropTypes.string,
  flexDirection: PropTypes.string,
  backgroungColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullHeight: PropTypes.bool,
};

export default BaseContainer;
