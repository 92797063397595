import { fork, takeEvery, put, select, call } from 'redux-saga/effects';
import types from './types';
import { SettingsActions } from '../Settings';
import { UserActions } from '../User';
import { GameActions } from '../Game';
import RulesActions from './actions';

function* handleRollRule(context, { data }) {
  const {
    game: { controlledPlayer },
  } = yield select((state) => state);
  yield put(
    RulesActions.setRuleToRoll({ player: controlledPlayer, rule: data }),
  );
  yield put(SettingsActions.setShowRuleRollModal(true));
}

function* handleRollNewRules(context) {
  const { apiService } = context;

  const {
    user: { sessionToken },
    rules: { ruleToRoll },
  } = yield select((s) => s);

  const { rolledNewRules, newState } = yield call(apiService.rerollRule, {
    sessionToken,
    ruleToRoll,
  });

  yield put(UserActions.restoreUser(newState.user));
  yield put(RulesActions.setRolledNewRules(rolledNewRules));
}

function* handleChooseSelectedRule(context) {
  yield put(SettingsActions.setShowRuleRollModal(false));
  const { apiService } = context;

  const {
    user: { sessionToken },
    rules: { selectedRule },
  } = yield select((s) => s);

  const response = yield call(apiService.selectRolledRule, {
    sessionToken,
    selectedRule,
  });

  yield put(GameActions.restoreGame(response.newState.game));
  yield put(RulesActions.restartGame());
}

// **** WATCHERS ****

function* watchRollRule(context) {
  yield takeEvery(types.ROLL_RULE, handleRollRule, context);
}

function* watchRollNewRules(context) {
  yield takeEvery(types.ROLL_NEW_RULES, handleRollNewRules, context);
}

function* watchChooseSelectedRule(context) {
  yield takeEvery(
    types.CHOOSE_SELECTED_RULE,
    handleChooseSelectedRule,
    context,
  );
}

export default (context) => [
  fork(watchRollRule, context),
  fork(watchRollNewRules, context),
  fork(watchChooseSelectedRule, context),
];
