import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  margin: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
`;

const SpinnerOne = styled(FontAwesomeIcon)`
  animation: ${rotate360} 0.5s linear infinite;
  opacity: 0.2;
`;

const SpinnerTwo = styled(FontAwesomeIcon)`
  animation: ${rotate360} 1s linear infinite;
  opacity: 0.2;
  position: absolute;
  z-index: 3;
`;

const LoadingSpinner = () => {
  return (
    <Container>
      <SpinnerOne icon={faCircleNotch} />
      <SpinnerTwo icon={faCircleNotch} />
    </Container>
  );
};

export default LoadingSpinner;
