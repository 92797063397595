export default {
  grey: ['#0B0D12', '#121A20', '#1F282E', '#2A333C', '#303E47', '#364E57'],
  primary: '#651906',
  primaryActive: '#863E10',
  secondary: '#495558,',
  secondaryActive: '#5C929F',
  primaryText: '#D2C8AE',
  secondaryText: '#D9E6EB',
  brown: ['#231717', '#302220', '#302220'],
};
