import produce from 'immer';
import types from './types';
import initialState from './initialState';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_ROLLED_NEW_RULES: {
      draft.rolledNewRules = data;

      break;
    }

    case types.SET_RULE_TO_ROLL: {
      draft.ruleToRoll = data;

      break;
    }

    case types.SET_SELECTED_RULE: {
      draft.selectedRule = data;

      break;
    }

    case types.RESTART_GAME: {
      return initialState;
    }

    default: {
      break;
    }
  }
}, initialState);
