import types from './types';

const addSession = (data) => ({
  type: types.ADD_SESSION,
  data,
});

export default {
  addSession,
};
