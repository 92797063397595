import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getPhaseGoalsForCurrentPhase } from '../../../redux/Game/selectors';
import { getRulesForCurrentPhase } from '../../../redux/Rules/selectors';
import Title from '../../baseComponents/Title';
import BaseButton from '../../baseButton';
import LoadingSpinner from './LoadingSpinner';
import { SettingsActions } from '../../../redux/Settings';
import { GameActions } from '../../../redux/Game';
import colors from '../../../utils/colors';
import Checkbox from './Checkbox';
import RuleDetails from './RuleDetails';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding: 0 24px 24px 24px;
  width: 60%;
  max-width: 650px;
  border: 2px solid ${colors.grey[3]};
  border-radius: 8px;
  background-color: ${colors.grey[1]};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const Change = styled.div`
  font-size: 20px;
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  margin-top: 40px;
`;

const PhaseText = styled.p`
  margin: 30px auto 10px auto;
  font-size: 12px;
  width: 350px;
  color: ${colors.secondaryText};
`;

const Label = styled.div`
  margin-bottom: 0px;
  text-align: center;
  font-size: 1rem;
`;

const CommentInput = styled.textarea`
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid rgb(118, 118, 118);
  background-color: #444444;
  color: #ffffff;
  width: 30rem;
  height: 5rem;
  margin: 5px auto 25px auto;
`;

const PhaseModal = () => {
  const dispatch = useDispatch();
  const goals = useSelector((state) => getPhaseGoalsForCurrentPhase(state));
  const rules = useSelector((state) => getRulesForCurrentPhase(state));
  const { currentPhase, phaseLoading } = useSelector((state) => state.game);
  const { saveToHallOfFame, hallToFameComment } = useSelector(
    (state) => state.settings,
  );

  const handleOnChangeHallOfFame = () => {
    dispatch(SettingsActions.setSaveToHallOfFame(!!!saveToHallOfFame));
  };

  const handleOnChangeComment = (event) => {
    dispatch(SettingsActions.setHallToFameComment(event.target?.value));
  };

  const phaseText = 'Phase ' + currentPhase;
  let phaseGoals = [];
  phaseGoals = goals.map((goal) => {
    return (
      <>
        <Change key={goal.id}>
          {goal.displayName} +{goal.tokens}
          <FontAwesomeIcon icon={faCoins} style={{ marginLeft: 4 }} />
        </Change>
        {goal.additionalGoals?.map((additionalGoal) => (
          <Change key={additionalGoal.id}>
            {additionalGoal.displayName} +{additionalGoal.tokens}
            <FontAwesomeIcon icon={faCoins} style={{ marginLeft: 4 }} />
          </Change>
        ))}
      </>
    );
  });

  let newRules = [];
  newRules = rules.map((rule) => {
    return <RuleDetails rule={rule} key={rule.id} />;
  });

  const restartGame = () => {
    dispatch(SettingsActions.setShowPhaseModal(false));
    dispatch(GameActions.restartGame());
  };

  return (
    <Container>
      {currentPhase < 16 ? (
        <Modal>
          {phaseLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Title text={phaseText} />
              <SmallTitle>New rule:</SmallTitle>
              {newRules}
              <SmallTitle>New phase goals:</SmallTitle>
              {phaseGoals}
              <PhaseText>
                All goals except kill act boss are optional strech goals that
                you can do for some extra tokens. Tokens can be used to reroll
                your existing rules.
              </PhaseText>
              <BaseButton
                size="18"
                text="Continue"
                onClick={() =>
                  dispatch(SettingsActions.setShowPhaseModal(false))
                }
              />
            </>
          )}
        </Modal>
      ) : (
        <Modal>
          {phaseLoading ? (
            'loading'
          ) : (
            <>
              <Title text="Concratulations!" />
              <SmallTitle>Hall Of Fame?</SmallTitle>
              <PhaseText>
                You can save this playthrough to hall of fame. It will be
                available for every one to see the rules and how far you
                reached. Add a link to a video of the gameplay to below field if
                you want to get this run verified by others. Verified runs will
                be shown on their own leaderboard. GUI for Hall of Fame is still
                in the making, but you can add this game for later use and to
                help of the development. (not implemented yet...)
              </PhaseText>
              <Checkbox
                width="250px"
                checked={!!saveToHallOfFame}
                onChange={handleOnChangeHallOfFame}
                title="Add game to hall of fame"
                label="Add game to hall of fame"
              />
              <Label>Comment</Label>
              <CommentInput
                value={hallToFameComment}
                onChange={handleOnChangeComment}
                placeholder={
                  'Add your link to youtube channel or something if you have recorded the gameplay for checkup.'
                }
              />
              <BaseButton
                size="18"
                text="Start new game"
                onClick={restartGame}
              />
            </>
          )}
        </Modal>
      )}
    </Container>
  );
};

export default PhaseModal;
