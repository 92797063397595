import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getNeededTokensToRoll } from '../../../redux/Rules/selectors';
import BaseButton from '../../baseButton';
import { SettingsActions } from '../../../redux/Settings';
import { RulesActions } from '../../../redux/Rules';
import colors from '../../../utils/colors';
import RuleDetails from './RuleDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faStar } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding: 0 24px 24px 24px;
  width: 60%;
  max-width: 650px;
  border: 2px solid ${colors.grey[3]};
  border-radius: 8px;
  background-color: ${colors.grey[1]};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
`;

const ButtonContainer = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: space-evenly;
`;

const RolledRule = styled.button`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #302220;
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.brown[0]};
  color: ${colors.primaryText};
  padding: 0.2em 2rem;
  min-height: 38px;
  font-size: 18px;
  margin: 20px auto 1em auto;
`;

const RuleRollModal = () => {
  const dispatch = useDispatch();
  const rollCost = useSelector((state) => getNeededTokensToRoll(state));
  const { rolledNewRules, ruleToRoll, selectedRule } = useSelector(
    (state) => state.rules,
  );
  const { tokens } = useSelector((state) => state.game);
  const rollButtonText = (
    <>
      Reroll rule -{rollCost}{' '}
      <FontAwesomeIcon icon={faCoins} size="20" color={colors.primaryText} /> -
      {rollCost * 2}{' '}
      <FontAwesomeIcon icon={faStar} size="20" color={colors.primaryText} />
    </>
  );
  let newRules = [];
  newRules = rolledNewRules.map((rule) => {
    return (
      <RolledRule
        onClick={() => dispatch(RulesActions.setSelectedRule(rule))}
        key={rule.id}
        selected={selectedRule && rule.id === selectedRule.id}
      >
        {rule.name}
      </RolledRule>
    );
  });
  return (
    <Container>
      <Modal>
        <RuleDetails rule={ruleToRoll?.rule} />
        {rolledNewRules.length > 0 ? (
          <>
            <RolledRule
              onClick={() =>
                dispatch(RulesActions.setSelectedRule(ruleToRoll.rule))
              }
              key={ruleToRoll?.rule.id}
              selected={selectedRule && selectedRule.id === ruleToRoll?.rule.id}
            >
              {ruleToRoll?.rule.name}
            </RolledRule>
            <div>
              {newRules}
              <BaseButton
                size="18"
                text="Select"
                onClick={() => dispatch(RulesActions.chooseSelectedRule())}
                disabled={selectedRule === null}
              />
            </div>
          </>
        ) : (
          <ButtonContainer>
            <BaseButton
              disabled={rollCost > tokens}
              size="18"
              text={rollButtonText}
              onClick={() => dispatch(RulesActions.rollNewRules())}
              alt="You can try to reroll this rule to something else."
            />
            <BaseButton
              size="18"
              text="Close"
              onClick={() =>
                dispatch(SettingsActions.setShowRuleRollModal(false))
              }
            />
          </ButtonContainer>
        )}
      </Modal>
    </Container>
  );
};

export default RuleRollModal;
