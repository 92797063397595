import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseButton from '../../baseButton';
import { GameActions } from '../../../redux/Game';
import { UserActions } from '../../../redux/User';

const StartButton = () => {
  const {
    game: { controlledPlayer },
    user: { sessionToken, sessionName },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(GameActions.startGame());
  };
  const onClickRefresh = () => {
    dispatch(UserActions.loadSession(sessionToken));
  };
  const isDisabled = sessionName === '';
  return (
    <>
      {controlledPlayer === 0 ? (
        <BaseButton
          onClick={onClick}
          alt="Start the game and roll some rules!"
          size="24"
          text="START!"
          disabled={isDisabled}
        />
      ) : (
        <BaseButton
          onClick={onClickRefresh}
          alt="Start the game and roll some rules!"
          size="24"
          text="REFRESH!"
        />
      )}
    </>
  );
};

export default StartButton;
