import { all } from 'redux-saga/effects';
import { forkSagas as ForkRulesSagas } from './Rules';
import { forkSagas as ForkGameSagas } from './Game';
import { forkSagas as ForkUserSagas } from './User';

export default function* rootSaga(context) {
  yield all([
    ...ForkUserSagas(context),
    ...ForkRulesSagas(context),
    ...ForkGameSagas(context),
  ]);
}
